// cards list
export const cards = [
    {
        id: 1,
        image: 'testaments-1.png',
        name: 'Nelu',
        tag: '@Quality Support',
        content:
            'This is a quality team with quality support. Number of available modules is incredible. Anytime I thought "oh I wish it had this" I was able to find exactly that already pre-made in the template.',
        view: 1
    },
    {
        id: 2,
        name: 'Bente',
        tag: '@Customer Support',
        content:
            'Very good customer service! I liked the design and there was nothing wrong, but found out after testing that it did not quite match the functionality and overall design that I needed for my type of software. I therefore contacted customer service and it was no problem even though the deadline for refund had actually expired.',
        view: 1
    },
    {
        id: 3,
        image: 'testaments-2.png',
        name: 'William',
        tag: '@Code Quality',
        content:
            'One of the better themes I have used. Beautiful and clean design. Also included a NextJS project. Ultimately it didnt work out for my specific use case, but this is a well organized theme. Definitely keeping it in mind for future projects.',
        view: 1
    },
    {
        id: 4,
        name: 'Besart',
        tag: '@Customizability',
        content:
            'Very well written code and good structure. Very customizable and tons of nice components. Good documentation. Team is very responsive too',
        view: 1
    },
    {
        id: 5,
        name: 'Jean',
        tag: '@Well Written',
        content: 'Very well written. I use it as a base for completely rewriting our existing apps front end.',
        view: 0.7
    },
    {
        id: 6,
        name: 'Dillon',
        tag: '@Codebase',
        content:
            'The project template is great, as well as the codebase. I am a backend developer, new to frontend and learning. So this template is turning out to be a great foundation...',
        view: 0.7
    },
    {
        id: 7,
        image: 'testaments-4.png',
        name: 'Musaid',
        tag: '@Exceptional',
        content: 'Awesome design. Exceptional code quality. Great customer service. I would say, the best theme I have seen in a while',
        view: 0.7
    },
    {
        id: 8,
        image: 'testaments-3.png',
        name: 'Felipe',
        tag: '@Best MUI Template',
        content:
            'Fantastic design and good code quality. It is a great starting point for any new project. They provide plenty of premade components, page views, and authentication options. Definitely the best I have found for Material UI in Typescript',
        view: 0.7
    },
    {
        id: 9,
        name: 'Rodrigo',
        tag: '@Customer Service',
        content:
            'Excellent design, you can use in a new project or include in your current project. Multiple components for any use. Good code quality. Great customer service and support.',
        view: 0.3
    }
];
