import {
  aadhaarAgreementAPICallErrorAction,
  fetchChannelOnboardingQuestions,
  isAgreementDataSavedSuccessAction,
  isPdfSentOnMailAPICalling,
  questionsCount,
  questionsUpdateSuccessAction, requestUserProfileAction, verifyAgreementAadhaarStatus
} from "../../data-slices/dsa/channelOnboardingsSlice";
import axios from 'utils/axios';
import { openSnackbar } from "store/slices/snackbar";
import { fetchUserProfileApi } from "./profile";

export function fetchChannelOnboardingApi(body: { limit: number | 100, page: number | 1, search: string | '', companyId?: number }) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/dsa/channel-onboarding?search=${body.search ?? ''}&limit=${body.limit}&page=${body.page}${body?.companyId ? `'&companyId='${body?.companyId}` : ''}`,
        method: 'get',

      });
      if (response.data.success) {
        dispatch(fetchChannelOnboardingQuestions(response.data.questions));
        dispatch(questionsCount(response.data.count));
      } else {
        dispatch(fetchChannelOnboardingQuestions({}));
        dispatch(questionsCount(null));
      }
    } catch (error: any) {
      dispatch(fetchChannelOnboardingQuestions({}));
      dispatch(questionsCount(null));
      if (error && error.message) {
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: error.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    }
  };
}

export function updateChannelSettingsApi(body: { id: number, type: 'sendLoginLink' | 'sendVerifyLink' | '' }) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/dsa/channel-onboarding/settings`,
        method: 'post',
        data: body

      });
      if (response.data.success) {
        //dispatch(addUpdateCompanySuccess(response.data.success));
        // dispatch(getDSACompanyProfileAPI(body));
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: response.data.message,
            variant: 'alert',
            alert: {
              color: 'success'
            }
          }));
      }
    } catch (error: any) {
      if (error && error.message) {
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: error.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    }
  };
}

export function updateChannelRequestProfileApi(body: any) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/dsa/channel-onboarding/questions`,
        method: 'post',
        data: body

      });
      if (response.data.success) {
        if (body?.isUpdateAgreement === 1) dispatch(isAgreementDataSavedSuccessAction(response.data?.success));
        if (body && (body.isDigitalSign === 'aadhaar' || body.type === 'udyog')) {
          dispatch(verifyAgreementAadhaarStatus(response.data));
        }
        if (body && (body.isPdfSendOnMail)) {
          dispatch(isPdfSentOnMailAPICalling(false));
        }
        if (body && body.isDigitalSign === 'verify') dispatch(verifyAgreementAadhaarStatus(response.data));
        if (body?.agreement) {
          dispatch(fetchUserProfileApi({ agreement: true }));
        } else {
          dispatch(fetchChannelOnboardingUserApi());
        }
        if (!body?.timelineMeta && body?.isNotifications === undefined) {
          dispatch(
            openSnackbar({
              open: true,
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
              message: response.data.message,
              variant: 'alert',
              alert: {
                color: 'success'
              }
            }));
        }
      }
    } catch (error: any) {
      if (error && error.message) {
        if (body?.isUpdateAgreement) dispatch(isAgreementDataSavedSuccessAction(false));
        if (body.isDigitalSign === 'aadhaar') dispatch(aadhaarAgreementAPICallErrorAction(true));
        if (body && (body.isPdfSendOnMail)) {
          dispatch(isPdfSentOnMailAPICalling(false));
        }
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: error.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    }
  };
}

export function fetchChannelOnboardingUserApi() {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/dsa/channel-onboarding/profile`,
        method: 'post',

      });
      if (response.data.success) {
        dispatch(requestUserProfileAction(response.data?.user));
      } else {
        dispatch(requestUserProfileAction({}));
      }
    } catch (error) {
      dispatch(requestUserProfileAction({}));
    }
  };
}


export function createOnboardingQuestionsApi(body: any) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/dsa/channel-onboarding/create`,
        method: 'post',
        data: body

      });
      if (response.data.success) {
        dispatch(questionsUpdateSuccessAction(response.data.success));
        dispatch(fetchChannelOnboardingApi({ limit: 100, page: 1, search: '' }));
      } else {
        dispatch(questionsUpdateSuccessAction(response?.data?.message));
      }
      dispatch(
        openSnackbar({
          open: true,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: response.data.success ? 'success' : 'error'
          }
        }));

    } catch (error: any) {
      console.log(error, 'error==========')
      if (error && error.message) {
        dispatch(questionsUpdateSuccessAction(error?.message));
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: error.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    }
  };
}

export function deleteOnboardingQuestionsApi(body: { ids: any[] }) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/dsa/channel-onboarding`,
        method: 'delete',
        data: body
      });
      if (response.data.success) {
        dispatch(fetchChannelOnboardingApi({ limit: 100, page: 1, search: '' }));
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: response.data.message,
            variant: 'alert',
            alert: {
              color: 'success'
            }
          }));
      }
    } catch (error: any) {
      if (error && error.message) {
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: error.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    }
  };
}

export function updateOnboardingQuestionsApi(body: any) {
  return async (dispatch: any) => {
    try {
      const id = body.id;
      const response = await axios({
        url: `/dsa/channel-onboarding/${id}`,
        method: 'put',
        data: body

      });
      if (response.data.success) {
        dispatch(questionsUpdateSuccessAction(true));
        dispatch(fetchChannelOnboardingApi({ limit: 100, page: 1, search: '' }));
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: response.data.message,
            variant: 'alert',
            alert: {
              color: 'success'
            }
          }));
      }
    } catch (error: any) {
      if (error && error.message) {
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: error.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    }
  };
}




