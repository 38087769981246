// third-party

import { NavItemType } from 'types';

// assets
import {
    IconApps, IconUserCheck, IconBasket, IconMessages, IconLayoutKanban, IconMail,
    IconCalendar, IconNfc, IconSettings, IconClipboardPlus,
    IconBuildingCommunity, IconBuildingBank, IconClipboardList, IconBook, IconBooks, IconGizmo, IconUserPlus, IconUsers
} from '@tabler/icons';

// constant
const icons = {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconBuildingCommunity,
    IconBuildingBank,
    IconClipboardList,
    IconBook,
    IconBooks,
    IconGizmo,
    IconUserPlus,
    IconUsers, IconSettings, IconClipboardPlus,
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const channel: NavItemType = {
    id: 'dsaChannelUser',
    title: 'Application',
    icon: icons.IconApps,
    type: 'group',
    permissions: ['DailyTask', 'ChannelMis', 'Images', 'Users', 'Role', 'Reports'],
    isDevMode: false,
    children: [
        {
            id: 'daily-sales',
            title: 'Daily Sales Tracker',
            type: 'item',
            permissions: ['DailyTask'],
            access: 'read',
            icon: icons.IconUserCheck,
            url: '/daily-sales'
            //disabled: true,
        },
        {
            id: 'mis-uploader',
            title: 'MIS Uploader',
            type: 'collapse',
            disabled: true,
            permissions: ['DailyTask'],
            icon: icons.IconBuildingBank,
            children: [
                {
                    id: 'add-channel-mis',
                    title: 'Add Channel MIS',
                    type: 'item',
                    permissions: ['ChannelMis'],
                    access: 'create',
                    url: '/mis-uploader/add-channel-mis'
                },
                {
                    id: 'channel-mis',
                    title: 'Channel MIS List',
                    permissions: ['ChannelMis'],
                    access: 'read',
                    type: 'item',
                    url: '/mis-uploader/channel-mis'
                },
            ]
        },
        {
            id: 'mis-review',
            title: 'MIS Review',
            type: 'collapse',
            disabled: true,
            permissions: ['DailyTask'],
            //access: 'read',
            icon: icons.IconBuildingBank,
            children: [
                {
                    id: 'review-mis',
                    title: 'Review Channel MIS',
                    type: 'item',
                    permissions: ['ChannelMis'],
                    access: 'review',
                    url: '/mis-uploader/review-mis'
                },
            ]
        },
        {
            id: 'reports',
            title: 'Reports',
            type: 'collapse',
            disabled: true,
            permissions: ['Reports'],
            icon: icons.IconBuildingBank,
            children: [
                {
                    id: 'reports-list',
                    title: 'Reports List',
                    type: 'item',
                    permissions: ['ChannelMis'],
                    access: 'read',
                    //url: '/reports/list'
                },
            ]
        },
        {
            id: 'users',
            title: 'Users',
            type: 'collapse',
            icon: icons.IconUsers,
            permissions: ['Users'],
            disabled: false,
            children: [
                {
                    id: 'add-user',
                    title: 'Add User',
                    type: 'item',
                    url: '/user/add',
                    permissions: ['Users'],
                    access: 'create',
                },
                {
                    id: 'list-user',
                    title: 'User List',
                    type: 'item',
                    url: '/user/list',
                    permissions: ['Users'],
                    access: 'read',
                },
                {
                    id: 'roles-permissions',
                    title: 'Roles & Permissions',
                    type: 'collapse',
                    permissions: ['Role'],
                    children: [
                        {
                            id: 'add-role',
                            title: 'Add Role',
                            type: 'item',
                            url: '/user/role/add',
                            permissions: ['Role'],
                            access: 'create',
                        },
                        {
                            id: 'list-role',
                            title: 'Role List',
                            type: 'item',
                            url: '/user/role/list',
                            permissions: ['Role'],
                            access: 'read',
                        },
                    ]
                }

            ]
        },
    ]
};

export default channel;





