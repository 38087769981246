// third-party
import { FormattedMessage } from 'react-intl';

import { NavItemType } from 'types';

// assets
import { IconApps, IconUserCheck, IconBasket, IconMessages, IconLayoutKanban, IconMail, IconCalendar, IconNfc, 
    IconBuildingCommunity, IconBuildingBank, IconClipboardList, IconBook, IconGizmo, IconUserPlus, IconUsers } from '@tabler/icons';

// constant
const icons = {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconBuildingCommunity,
    IconBuildingBank,
    IconClipboardList,
    IconBook,
    IconGizmo,
    IconUserPlus,
    IconUsers
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const application: NavItemType = {
    id: 'admin',
    title: 'Admin',
    icon: icons.IconApps,
    type: 'group',
    children: [
        {
            id: 'help-center',
            title: 'Help Center',
            type: 'collapse',
            icon: icons.IconBuildingCommunity,
            children: [
                {
                    id: 'list-category',
                    title: 'Category List',
                    type: 'item',
                    url: '/help-center/category/list'
                },
                {
                    id: 'add-article',
                    title: 'Add Article',
                    type: 'item',
                    url: '/help-center/add'
                },
                {
                    id: 'list-articles',
                    title: 'Articles List',
                    type: 'item',
                    url: '/help-center/list'
                   
                },
            ]
        },
        {
            id: 'dsa',
            title: 'DSA',
            type: 'collapse',
            icon: icons.IconBuildingCommunity,
            children: [
                {
                    id: 'add-dsa',
                    title: 'Add DSA',
                    type: 'item',
                    url: '/dsa/add'
                },
                {
                    id: 'list-dsa',
                    title: 'DSA List',
                    type: 'item',
                    url: '/dsa/list'
                   
                },
                {
                    id: 'users',
                    title: 'Users',
                    type: 'collapse',
                    children: [
                        {
                            id: 'list-user',
                            title: 'User List',
                            type: 'item',
                            url: '/dsa/user/list'
                           
                        },
                        // {
                        //     id: 'add-user',
                        //     title: 'Add User',
                        //     type: 'item',
                        //     url: '/dsa/user/add'
                           
                        // }
                    ]
                }
            ]
        },
        {
            id: 'company',
            title: 'Company',
            type: 'collapse',
            icon: icons.IconBuildingCommunity,
            children: [
                {
                    id: 'add-company',
                    title: 'Add Company',
                    type: 'item',
                    url: '/company/add'
                },
                {
                    id: 'list-company',
                    title: 'Company List',
                    type: 'item',
                    url: '/company/list'
                   
                },
                {
                    id: 'users',
                    title: 'Users',
                    type: 'collapse',
                    children: [
                        {
                            id: 'list-user',
                            title: 'User List',
                            type: 'item',
                            url: '/company/user/list'
                           
                        },
                        // {
                        //     id: 'add-user',
                        //     title: 'Add User',
                        //     type: 'item',
                        //     url: '/dsa/user/add'
                           
                        // }
                    ]
                }
            
            ]
        },
        {
            id: 'bank',
            title: 'Bank',
            type: 'collapse',
            icon: icons.IconBuildingBank,
            children: [
                {
                    id: 'add-bank',
                    title: 'Add Bank',
                    type: 'item',
                    url: '/bank/add'
                },
                {
                    id: 'list-bank',
                    title: 'Bank List',
                    type: 'item',
                    url: '/bank/list'
                   
                },
            
            ]
        },
        {
            id: 'product',
            title: 'Product',
            type: 'collapse',
            icon: icons.IconBook,
            children: [
                {
                    id: 'add-product-types',
                    title: 'Add Product Types',
                    type: 'item',
                    url: '/product-types/add'
                },
                {
                    id: 'list-product-types',
                    title: 'Product Types List',
                    type: 'item',
                    url: '/product-types/list'
                   
                },
                {
                    id: 'add-product',
                    title: 'Add Product',
                    type: 'item',
                    url: '/product/add'
                },
                {
                    id: 'list-product',
                    title: 'Product List',
                    type: 'item',
                    url: '/product/list'
                   
                },
            
            ]
        },
        {
            id: 'channel',
            title: 'Channel',
            type: 'collapse',
            icon: icons.IconGizmo,
            children: [
                {
                    id: 'add-channel',
                    title: 'Add Channel',
                    type: 'item',
                    url: '/channel/add'
                },
                {
                    id: 'list-channel',
                    title: 'Channel List',
                    type: 'item',
                    url: '/channel/list'
                   
                },
                {
                    id: 'channel-keywords',
                    title: 'Channel Keywords',
                    type: 'item',
                    url: '/channel/keywords'
                   
                },
                {
                    id: 'users',
                    title: 'Users',
                    type: 'collapse',
                    children: [
                        {
                            id: 'list-user',
                            title: 'User List',
                            type: 'item',
                            url: '/channel/user/list'
                           
                        },
                        // {
                        //     id: 'add-user',
                        //     title: 'Add User',
                        //     type: 'item',
                        //     url: '/dsa/user/add'
                           
                        // }
                    ]
                }
            
            ]
        },
        {
            id: 'users',
            title: 'Users',
            type: 'collapse',
            icon: icons.IconUserCheck,
            children: [
                {
                    id: 'add-user',
                    title: 'Add User',
                    type: 'item',
                    url: '/user/add'
                },
                {
                    id: 'list-user',
                    title: 'User List',
                    type: 'item',
                    url: '/user/list'
                },
                {
                    id: 'roles-permissiosn',
                    title: 'Roles & Permissions',
                    type: 'collapse',
                    children: [
                        {
                            id: 'add-role',
                            title: 'Add Role',
                            type: 'item',
                            url: '/user/role/add'
                        },
                        {
                            id: 'list-role',
                            title: 'Role List',
                            type: 'item',
                            url: '/user/role/list'
                        },
                    ]
                }
               
            ]
        },

        // {
        //     id: 'Home',
        //     title: 'HOME',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'individual-profile',
        //             title: 'Individual Profile',
        //             type: 'item',
        //             url: '/user/profile'
        //         },
        //         {
        //             id: 'company-profile',
        //             title: 'Company Profile',
        //             type: 'item',
        //             url: '/company/profile'
        //         },
        //         {
        //             id: 'manage-white-listing',
        //             title: 'Manage white listing',
        //             type: 'item',
        //             url: '/company'
        //         },
        //         {
        //             id: 'profile-password',
        //             title: 'Change Password',
        //             type: 'item',
        //             url: '/company/profile'
        //         },
        //         {
        //             id: 'login-profile',
        //             title: 'Login Details',
        //             type: 'item',
        //             url: '/company/profile'
        //         }   
        //     ]
        // },
        // {
        //     id: 'APPLICATION',
        //     title: 'APPLICATION',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'List-Applications',
        //             title: 'List Applications',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'View-Applications',
        //             title: 'View Applications',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'Pending-Applications',
        //             title: 'Pending Applications',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'Completed-Applications',
        //             title: 'Completed Applications',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'Search-Applications',
        //             title: 'Search Applications',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //     ]
        // },
        // {
        //     id: 'manage-roles',
        //     title: 'MANAGE ROLES',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'add-agency-user',
        //             title: 'Add Agency User',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'agency-user-list',
        //             title: 'Agency user list',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'manage-agency-user',
        //             title: 'Manage Agency User',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'add-new-role',
        //             title: 'Add New Role',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'assign-permissions',
        //             title: 'Assign Permissions',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'access-role',
        //             title: 'Access role',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'role-list',
        //             title: 'Role List',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'team-login-details',
        //             title: 'Team Login Details',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'attendance',
        //             title: 'Attendance',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'live-team-tracking',
        //             title: 'Live Team Tracking',
        //             type: 'item',
        //             url: '/user/'
        //         },
                
        //     ]
        // },
        // {
        //     id: 'companies',
        //     title: 'COMPANIES',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'company-master',
        //             title: 'Company Master',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'add-company-name',
        //             title: 'Add Company Name',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'list-company',
        //             title: 'List Company',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'active-company',
        //             title: 'Active Company',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'disabled-company',
        //             title: 'Disabled Company',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'permanent-delete-company',
        //             title: 'Permanent Delete Company',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'add-company-user',
        //             title: 'Add Company User',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'list-lompany-executive',
        //             title: 'List Company Executive',
        //             type: 'item',
        //             url: '/user/'
        //         }
                
        //     ]
        // },
        
        // {
        //     id: 'dedupe',
        //     title: 'DEDUPE',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'add-duplicate-data',
        //             title: 'Add Duplicate Data',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'check-duplicate',
        //             title: 'Check Duplicate',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'adddefaulter-ata',
        //             title: 'Add Defaulter Data',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'check-defaulter',
        //             title: 'Check Defaulter',
        //             type: 'item',
        //             url: '/user/'
        //         } 
        //     ]
        // },
        // {
        //     id: 'broadcast',
        //     title: 'BROADCAST',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'add-Manage-ontact',
        //             title: 'Add + Manage Contact',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'add-manage-group',
        //             title: 'Add + Manage Group',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'add-manage-templates',
        //             title: 'Add + Manage Templates',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'add-manage-reccuring-alerts',
        //             title: 'Add + Manage Reccuring Alerts',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'import-contacts',
        //             title: 'Import Contacts',
        //             type: 'item',
        //             url: '/user/'
        //         } ,
        //         {
        //             id: 'schedule-instant-alert',
        //             title: 'Schedule + Instant Alert',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'all-delivery-report',
        //             title: 'All Delivery Report',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //     ]
        // },
        // {
        //     id: 'system-alerts',
        //     title: 'SYSTEM ALERT & BALANCES',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'google-api-setting',
        //             title: 'Google API setting',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'whatsApp-setting',
        //             title: 'SMS/Mail /Voice/WhatsApp Setting',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'kyc-api-settings',
        //             title: 'KYC API settings',
        //             type: 'item',
        //             url: '/user/'
        //         }
        //     ]
        // },
        // {
        //     id: 'state-n-analytics',
        //     title: 'STATS n ANALYTICS',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'company-wise',
        //             title: 'Company Wise',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'user-wise',
        //             title: 'User Wise',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'application-wise',
        //             title: 'Application Wise',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'tat-wise',
        //             title: 'TAT Wise',
        //             type: 'item',
        //             url: '/user/'
        //         }
        //     ]
        // },
        // {
        //     id: 'adult-footprint',
        //     title: 'AUDIT FOOTPRINT',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'ip-tracker',
        //             title: 'IP Tracker',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'audit-log',
        //             title: 'Audit log',
        //             type: 'item',
        //             url: '/user/'
        //         }
        //     ]
        // },
        // {
        //     id: 'new-notification',
        //     title: 'NEWS & NOTIFICATION',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'add-news',
        //             title: 'Add News',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'list-news',
        //             title: 'List News',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'sound-manager',
        //             title: 'Sound Manager',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'list-sound-manager',
        //             title: 'List Sound Manager',
        //             type: 'item',
        //             url: '/user/'
        //         }
        //     ]
        // },
        // {
        //     id: 'app-configuration',
        //     title: 'APP CONFIGURATION',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'theme-setting',
        //             title: 'Theme setting',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'app-setting',
        //             title: 'App Setting',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'alertsnotification-setting',
        //             title: 'Alerts & Notification Setting',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'database-exp',
        //             title: 'Database - Exp/Dwnld/Purge',
        //             type: 'item',
        //             url: '/user/'
        //         }
        //     ]
        // },
        // {
        //     id: 'billing',
        //     title: 'BILLING',
        //     type: 'collapse',
        //     icon: icons.IconUserCheck,
        //     children: [
        //         {
        //             id: 'company-billing',
        //             title: 'Company Billing',
        //             type: 'item',
        //             url: '/user/'
        //         },
        //         {
        //             id: 'executive-billing',
        //             title: 'Executive Billing',
        //             type: 'item',
        //             url: '/user/'
        //         }
        //     ]
        // },
        
    ]
};

export default application;





