import { useRoutes } from 'react-router-dom';
import { useSelector } from 'store';
import MaintenanceError from 'views/pages/maintenance/Error';
import PagesLanding from 'views/pages/landing';
import FaqApp from 'views/application/faq';
import FaqAppCategory from 'views/application/faq/App';

// Importing route components
import AdminLoginRoutes from './AdminLoginRoutes';
import AdminRoutes from './AdminRoutes';
import DsaLoginRoutes from './DsaLoginRoutes';
import DsaRoutes from './DsaRoutes';
import DsaChannelRoutes from './DsaChannelRoutes';
import DsaChannelOnboardingRoutes from './DsaChannelOnboarding';
import * as UserRoutes from './user'; // Assuming user routes are in a folder called "user"

export default function ThemeRoutes() {
    const user: any = useSelector((state) => state?.account?.user);
    const userType = user?.type || null;
    const isDevelopment = user?.isDevelopment === '1' || false; // Use optional chaining here as well
    const location = window.location.host;
    const pathArr = location.replace(/^\/|\/$/g, '').split('.').slice();
    let baseRoutes = [
        { path: '/404', element: <MaintenanceError /> }
    ];

    const mode = `${process?.env?.REACT_APP_ENV}`;
    // Add specific routes based on user type
    if (((mode === 'development' && pathArr.length > 1) || (mode !== 'development' && pathArr.length > 2)) && pathArr.includes('admin')) {
        baseRoutes.push(AdminLoginRoutes, AdminRoutes);
    } else {
        if (((mode === 'development' && pathArr.length > 0) || (mode !== 'development' && pathArr.length > 1)) && (userType === 'dsa' || userType === 'dsa_user' || userType === 'channel' || userType === 'channel_user')) {
            if (isDevelopment) {
                if (['dsa', 'dsa_user'].includes(userType)) baseRoutes.push(DsaRoutes);
                if (['channel', 'channel_user'].includes(userType)) baseRoutes.push(DsaChannelRoutes);
            } else {
                if (['dsa', 'dsa_user'].includes(userType)) baseRoutes.push(UserRoutes.DsaUserRoutes);
                if (['channel', 'channel_user'].includes(userType)) baseRoutes.push(UserRoutes.DsaUserChannelRoutes);
            }
        } else if ((((mode === 'development' && pathArr.length > 1) || (mode !== 'development' && pathArr.length > 2)) || userType === 'request') && !pathArr.includes('admin')) {
            baseRoutes.push(DsaLoginRoutes, DsaChannelOnboardingRoutes);
        } else {
            baseRoutes.push({ path: '/help', element: <FaqApp /> });
            baseRoutes.push({ path: '/help/:id', element: <FaqAppCategory /> });
            baseRoutes.push({ path: '/help/:id/:articleId/*', element: <FaqAppCategory /> });
            baseRoutes.push({ path: '/*', element: <PagesLanding /> });
        }
    }

    // Default landing page route
    //baseRoutes.push({ path: '/*', element: <PagesLanding /> });
    return useRoutes(baseRoutes);
}
