// third-party

import { NavItemType } from 'types';

// assets
import {IconUsers, IconDeviceAnalytics, IconApps, IconBuildingCommunity } from '@tabler/icons';

// constant
const icons = {
    IconUsers, IconApps, IconDeviceAnalytics, IconBuildingCommunity,
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const dsaChannelOnboarding: NavItemType = {
    id: 'dsaChannelOnboarding',
    title: 'On Boardings',
    icon: icons.IconApps,
    type: 'group',
    isDevMode: false,
    children: [
        {
            id: 'channel-onboarding',
            title: 'Channel Onboarding',
            type: 'item',
            url: '/channel-onboarding',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        },
    ]
};

export default dsaChannelOnboarding;





