import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { GuardProps } from 'types';
import { useEffect } from 'react';
import { DASHBOARD_PATH } from 'config';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
    const { isLoggedIn } = useAuth();
    const navigate = useNavigate();
    const pathArr = window.location.hostname.replace(/^\/|\/$/g, '').split('.').slice();
    const path = window.location.pathname;
    
    useEffect(() => {
        if (!isLoggedIn) {
            if (pathArr.includes('admin')) {
                navigate('/backend/login', { replace: true });
            } else {
                navigate('/login', { replace: true });
            }
        }
        if (isLoggedIn && path == '/login') {
            navigate(DASHBOARD_PATH, { replace: true });
        }  
    }, [isLoggedIn, navigate, pathArr]);

    return children;
};

export default AuthGuard;
