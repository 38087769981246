import { createSlice } from '@reduxjs/toolkit';

const channelCategorySlice = createSlice({
  name: 'category',
  initialState: {
    categories: [],
    editCategory: {},
    categoryCount: null,
    success: null,
  },
  reducers: {
    fetchChannelCategory(state, action) {
      state.categories = action.payload;
    },
    getChannelCategoryById(state, action) {
      state.editCategory = action.payload;
    },
    setCategoryCount(state, action) {
      state.categoryCount = action.payload ? action.payload : 0;
    },
    setSuccess(state, action) {
      state.success = action.payload;
    }
  },
});

export const {
  fetchChannelCategory,
  setCategoryCount, getChannelCategoryById,
  setSuccess
} = channelCategorySlice.actions;

export default channelCategorySlice.reducer;
