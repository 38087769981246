import { createSlice } from '@reduxjs/toolkit';

const misDsaCheckerSlice = createSlice({
  name: 'misDsaChecker',
  initialState: {
    channels: [],
    channelsCounts: 0,
    channelsMis: [],
    channelsCategories: [],
    channelsMisCount: 0,
    editChannelsMis: {},
    channelsMisBanks: [],
    channelsMisProductType: [],
    channelsMisProduct: [],
    bankMis: [],
  },
  reducers: {
    fetchCheckerChannels(state, action) {
      state.channels = action.payload;
    },
    fetchCheckerChannelsCategories(state, action) {
      state.channelsCategories = action.payload;
    },
    fetchCheckerChannelsCounts(state, action) {
      state.channelsCounts = action.payload;
    },
    fetchCheckerChannelsMisList(state, action) {
      state.channelsMis = action.payload;
    },
    fetchMisProductAndMetaUpdate(state, action) {
      const { productTypes, products, banks } = action.payload;
      return {
        ...state,
        channelsMisProductType: productTypes ? productTypes : [],
        channelsMisBanks: (banks && banks?.length) ? banks : [],
        channelsMisProduct: products ? products : []
      };
    },
    fetchCheckerChannelsMisCounts(state, action) {
      state.channelsMisCount = action.payload;
    },
  },
});

export const { fetchCheckerChannels, fetchCheckerChannelsCategories, fetchMisProductAndMetaUpdate, fetchCheckerChannelsCounts, fetchCheckerChannelsMisCounts, fetchCheckerChannelsMisList } = misDsaCheckerSlice.actions;

export default misDsaCheckerSlice.reducer;