import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { CompanyRoutes } from './dsaRoutes/companyRoutes';
import { MisRoutes } from './dsaRoutes/misRoutes';
import { ChannelRoutes } from './dsaRoutes/channelRoutes';


const CompanySettings = Loadable(lazy(() => import('views/application/dsa/company/settings/AdvanceSettings')));
const AccountProfile = Loadable(lazy(() => import('views/application/dsa/company/settings/AccountProfile')));
const EmailProfile = Loadable(lazy(() => import('views/application/dsa/company/settings/EmailProfile')));


const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardAnalytics = Loadable(lazy(() => import('views/dashboard/Analytics')));

const DailySales = Loadable(lazy(() => import('views/application/dsa/channelSalesTracker')));
const CreateDailySales = Loadable(lazy(() => import('views/application/dsa/channelSalesTracker/AddTask')));
const EditDailySales = Loadable(lazy(() => import('views/application/dsa/channelSalesTracker/EditTask')));

const Bank = Loadable(lazy(() => import('views/application/dsa/bank')));

const BankContact = Loadable(lazy(() => import('views/application/dsa/bankContact')));
const AddContact = Loadable(lazy(() => import('views/application/dsa/bankContact/AddContact')));
const AddIntegrations = Loadable(lazy(() => import('views/application/dsa/apiIntegrations/bank/AddIntegrations')));

const Product = Loadable(lazy(() => import('views/application/dsa/product')));

const Profile = Loadable(lazy(() => import('views/application/dsa/profile')));

const User = Loadable(lazy(() => import('views/application/dsa/users')));
const AddUser = Loadable(lazy(() => import('views/application/dsa/users/AddUser')));
const EditUser = Loadable(lazy(() => import('views/application/dsa/users/profile')));

const RoleAndPermissions = Loadable(lazy(() => import('views/application/dsa/roles')));
const AddRole = Loadable(lazy(() => import('views/application/dsa/roles/AddRole')));
const EditRole = Loadable(lazy(() => import('views/application/dsa/roles/EditRole')));

const PolicyRules = Loadable(lazy(() => import('views/application/dsa/policies')));
const AddPolicy = Loadable(lazy(() => import('views/application/dsa/policies/addPolicy')));

const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));

const CodeMaster = Loadable(lazy(() => import('views/application/dsa/bankCodeMaster')));
const AddCodeMaster = Loadable(lazy(() => import('views/application/dsa/bankCodeMaster/Introduction')));
const EditCodeMaster = Loadable(lazy(() => import('views/application/dsa/bankCodeMaster/EditCodeMaster')));

const AddReport = Loadable(lazy(() => import('views/application/dsa/reports/AddReport')));
const ReportList = Loadable(lazy(() => import('views/application/dsa/reports')));

const Whatsapp = Loadable(lazy(() => import('views/application/dsa/whatsapp')));

const AddUsesTerms = Loadable(lazy(() => import('views/application/dsa/agreement/AddUsesTerms')));
const ViewUsesTerms = Loadable(lazy(() => import('views/application/dsa/agreement')));
const EditAgreement = Loadable(lazy(() => import('views/application/dsa/agreement/EditAgreement')));

const BankAPIs = Loadable(lazy(() => import('views/application/dsa/apiIntegrations/bank')));
const SmtpAPIs = Loadable(lazy(() => import('views/application/dsa/apiIntegrations/smtp')));
const SmsAPIs = Loadable(lazy(() => import('views/application/dsa/apiIntegrations/sms')));
const WhatsappAPIs = Loadable(lazy(() => import('views/application/dsa/apiIntegrations/whatsapp')));
const VoiceCallAPIs = Loadable(lazy(() => import('views/application/dsa/apiIntegrations/voiceCall')));

const AddNotification = Loadable(lazy(() => import('views/application/dsa/notifications/AddNotification')));
const Notifications = Loadable(lazy(() => import('views/application/dsa/notifications')));
const NotificationsSetting = Loadable(lazy(() => import('views/application/dsa/notifications/NotificationsSetting')));
const EditNotification = Loadable(lazy(() => import('views/application/dsa/notifications/EditNotification')));
const CreateMailTemplate = Loadable(lazy(() => import('views/application/dsa/notifications/mailTemplate/CreateMailTemplate')));
const ChannelOnboarding = Loadable(lazy(() => import('views/application/dsa/channel/onboarding')));

// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [...MisRoutes,...CompanyRoutes, ...ChannelRoutes,
        {
            path: '/dashboard',
            element: <DashboardDefault />,           
        },
        {
            path: '/user/verify',
            element: <DashboardDefault />
        },
        {
            path: '/',
            element: <DashboardDefault />,           
        },
        {
            path: '/channel-onboarding',
            element: <ChannelOnboarding />,           
        },
        {
            path: '/dashboard/analytics',
            element: <DashboardAnalytics />
        },
        {
            path: '/daily-sales',
            element: <DailySales/>
        },
        {
            path: '/daily-sales/add-task',
            element: <CreateDailySales/>
        },
        {
            path: '/daily-sales/:id',
            element: <EditDailySales/>
        },
        {
            path: '/bank/list',
            element: <Bank/>
        },
        {
            path: '/bank-api',
            element: <BankAPIs/>
        },
        {
            path: '/sms-api',
            element: <SmsAPIs/>
        },
        {
            path: '/whatsapp-api',
            element: <WhatsappAPIs/>
        },
        {
            path: '/smtp-api',
            element: <SmtpAPIs/>
        },
        {
            path: '/voice-call-api',
            element: <VoiceCallAPIs/>
        },
        {
            path: '/bank-api',
            element: <BankAPIs/>
        },
        {
            path: '/bank-integrations/add',
            element: <AddIntegrations/>
        },
        {
            path: '/bank-contact/list',
            element: <BankContact/>
        },
        {
            path: '/bank-contact/add',
            element: <AddContact/>
        },
        {
            path: '/bank/code',
            element: <CodeMaster/>
        },
       
        {
            path: '/bank/add-code',
            element: <AddCodeMaster/>
        },
        {
            path: '/bank/code/:id',
            element: <EditCodeMaster/>
        },    
        {
            path: '/notification',
            element: <Notifications/>
        },
        {
            path: '/notification/settings',
            element: <NotificationsSetting/>
        },
        
        {
            path: '/advance-limit',
            element: <CompanySettings/>
        },
        {
            path: '/account-profile',
            element: <AccountProfile/>
        },
        {
            path: '/email-smtp',
            element: <EmailProfile/>
        },
        {
            path: '/notification/add',
            element: <AddNotification/>
        },
        {
            path: '/notification/edit/:id',
            element: <EditNotification/>
        },
        {
            path: '/notification/mail/template/create',
            element: <CreateMailTemplate/>
        },
        {
            path: '/reports/add',
            element: <AddReport/>
        },
        {
            path: '/whatsapp',
            element: <Whatsapp/>
        },
        {
            path: '/reports/list',
            element: <ReportList/>
        },
        {
            path: '/product/list',
            element: <Product/>
        },
        {
            path: '/agreement/list',
            element: <ViewUsesTerms/>
        },
        {
            path: '/agreement/:id',
            element: <EditAgreement/>
        },
        {
            path: '/agreement/add',
            element: <AddUsesTerms/>
        },
        {
            path: '/policies/list',
            element: <PolicyRules/>
        },
        {
            path: '/policies/add',
            element: <AddPolicy/>
        },
        {
            path: '/profile',
            element: <Profile/>
        },
        {
            path: '/user/list',
            element: <User/>
        },
        {
            path: '/user/add',
            element: <AddUser/>
        },
        {
            path: '/user/edit/:id',
            element: <EditUser/>
        },
        {
            path: '/user/role/list',
            element: <RoleAndPermissions />
        },
        {
            path: '/user/role',
            element: <RoleAndPermissions />
        },
        {
            path: '/user/role/add',
            element: <AddRole/>
        }
        ,
        {
            path: '/user/role/edit/:id',
            element: <EditRole/>
        },
        {
            path: '/*',
            element: <MaintenanceError/>
        }


    ],

};

export default AdminRoutes;
