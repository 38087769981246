import moment from "moment";
import axios from "./axios";
//import html2pdf from 'html2pdf.js';


export function getAgeFromBirthday(birthday: string) {
  // Split the date string into day, month, and year
  const [day, month, year] = birthday.split('/');

  // Create a Date object for the birthdate
  const birthdate = new Date(`${year}-${month}-${day}`);

  // Get the current date
  const currentDate = new Date();

  // Calculate the age
  let age = currentDate.getFullYear() - birthdate.getFullYear();

  // Check if the current month and day are before the birth month and day
  if (
    currentDate.getMonth() < birthdate.getMonth() ||
    (currentDate.getMonth() === birthdate.getMonth() &&
      currentDate.getDate() < birthdate.getDate())
  ) {
    age--;
  }

  return age;
}

export function combineAddress(address: { house: any; landmark: any; loc: any; po: any; street: any; subdist: any; dist: any; state: any; postalCode: any; country: any; }) {
  // country : "India" dist : "Barmer" house : "165" landmark : "" loc : "" po : "Band" state : "Rajasthan" street : "Sutharon Ki Dhani" subdist : "Gudhamalani" vtc : "Band"
  const {
    house,
    landmark,
    loc,
    po,
    street,
    subdist,
    dist,
    state,
    postalCode,
    country
  } = address;

  let fullAddress = '';

  if (house) {
    fullAddress += house + ', ';
  }
  if (landmark) {
    fullAddress += landmark + ', ';
  }
  if (loc) {
    fullAddress += loc + ', ';
  }
  if (po) {
    fullAddress += po + ', ';
  }

  if (street) {
    fullAddress += street + ', ';
  }

  if (subdist) {
    fullAddress += subdist + ', \n';
  }

  if (dist) {
    fullAddress += dist + ', ';
  }

  if (state) {
    fullAddress += state + ', ';
  }

  if (postalCode) {
    fullAddress += postalCode + ', \n';
  }

  if (country) {
    fullAddress += country;
  }

  return fullAddress;
}

export const getLocationByPincode = async (pincode: string) => {
  if (pincode && String(pincode).length === 6) {
    try {
      const response = await axios.get(`/dsa/zipcode?pincode=${pincode}`);
      const data = response?.data?.data?.[0];
      return data;
    } catch (error: any) {
      console.error('Error fetching pincode data:', error);
      return null;
    }
  }
  return null;
};

function isISODateString(value: string): boolean {
  // Regular expression for ISO 8601 date format
  const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{3})?Z?$/;

  // Test the input string against the regular expression
  return isoDateRegex.test(value);
}

export const createData = (obj: any) => {
  const transformedData: any = [];
  if (Object.keys(obj).length) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        let transformedKey = key.replace(/([A-Z])/g, ' $1');
        transformedKey = transformedKey.split(' ').map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        let transformedValue = obj[key];
        if (isISODateString(transformedValue)) {
          transformedValue = moment(transformedValue).format("DD-MM-YYYY hh:mm:ss");
        }
        if (obj[key]?.length < 100) transformedData.push({ name: transformedKey, colon: ':', value: transformedValue });
      }
    }
  }
  return transformedData;
}

function getFileTypeFromExtension(extension: string) {
  const fileTypes: any = {
    pdf: 'application/pdf',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    // Add more file types as needed
  };

  const lowerCaseExtension = extension.toLowerCase();
  return fileTypes[lowerCaseExtension] || 'application/octet-stream';
}

export const getFileTypeFromName = (filename: string) => {
  try {
    const extension = filename?.split('.')?.pop();
    return extension ? getFileTypeFromExtension(extension) : '';
  } catch (e) {
    return '';
  }
}

export const handleCopyClick = (text: string) => {
  return navigator.clipboard.writeText(text);
};

export const getTwoDecimal = (str: number, isPercentage?: boolean) => {
  return  `${Number(str).toFixed(2)}${isPercentage ? '%' : ''}`
};

export function date(str: any) {
  return str ? moment(str).format("DD-MM-YYYY HH:MM:SS") : '';
}

export function capitalize(str: any) {
  return str ? String(str)?.charAt(0).toUpperCase() + String(str)?.slice(1) : '';
}

export function capitalizeAndSpacing(fieldName: string): string {
  if (!fieldName) return '';
  
  // Insert a space before each uppercase letter
  let formattedName = fieldName?.replace(/([A-Z])/g, ' $1');

  // Capitalize the first letter
  formattedName = formattedName?.charAt(0).toUpperCase() + formattedName.slice(1);

  return formattedName;
}

export function convertToMMYYYY(dateString: string): string {
  if (!dateString) return '';

  const months: { [key: string]: string } = {
    'jan': '01', 'january': '01',
    'feb': '02', 'february': '02',
    'mar': '03', 'march': '03',
    'apr': '04', 'april': '04',
    'may': '05',
    'jun': '06', 'june': '06',
    'jul': '07', 'july': '07',
    'aug': '08', 'august': '08',
    'sep': '09', 'september': '09',
    'oct': '10', 'october': '10',
    'nov': '11', 'november': '11',
    'dec': '12', 'december': '12'
  };

  dateString = dateString.toLowerCase().replace(/[\s'-]/g, '/');

  let match;

  // Format MM/YYYY or MM-YYYY or MM/YYYY
  match = dateString.match(/^(0[1-9]|1[0-2])\/?(\d{4})$/);
  if (match) {
    return `${match[1]}/${match[2]}`;
  }

  // Format MMM YYYY or MMM'YY or MMM YY
  match = dateString.match(/^([a-z]{3})\/?(\d{2,4})$/);
  if (match && months[match[1]]) {
    const month = months[match[1]];
    const year = match[2].length === 2 ? `20${match[2]}` : match[2];
    return `${month}/${year}`;
  }

  // Format MMMM YYYY
  match = dateString.match(/^([a-z]+)\/(\d{4})$/);
  if (match && months[match[1]]) {
    const month = months[match[1]];
    const year = match[2];
    return `${month}/${year}`;
  }

  return 'Invalid date format';
}

export function isDifferenceValid(value1: number, value2: number, minRange: number = 10, maxRange: number = 20): boolean {
  // If both values are below 5000, consider them the same if they differ by less than 1.
  if (value1 < 5000 && value2 < 5000) {
      return Math.abs(value1 - value2) < 1;
  }

  // If either value is 5000 or above, check the difference in points.
  const difference = Math.abs(value1 - value2);
  return difference >= minRange && difference <= maxRange;
}

export const isValidNumber = (value: any) => {
  if (!value) return false;
  const cleanedValue = value.replace(/,/g, '');
  return !isNaN(cleanedValue);
};

export const isValidNumberAndReturn = (value: string) => {
  return value ? Number(value?.replace(/,/g, '')) ? Number(value?.replace(/,/g, '')) : 0 : value;
};

export function isValidIFSCCode(ifsc_Code: string)
{
 
    // Regex to check valid
    // ifsc_Code  
    let regex = new RegExp(/^[A-Za-z]{4}0[A-Za-z0-9]{6}$/);
 
    // if ifsc_Code 
    // is empty return false
    if (ifsc_Code == null) {
        return true;
    }
 
    // Return true if the ifsc_Code
    // matched the ReGex
    if (regex.test(ifsc_Code) == true) {
        return true;
    }
    else {
        return false;
    }
}
export function isValidPan(pan: string)
{
 
    // Regex to check valid
    // ifsc_Code  
    let regex = new RegExp(/^[A-Za-z]{5}[0-9]{4}[A-Za-z]{1}$/);
 
    // if ifsc_Code 
    // is empty return false
    if (pan == null) {
        return true;
    }
 
    // Return true if the ifsc_Code
    // matched the ReGex
    if (regex.test(pan) == true) {
        return true;
    }
    else {
        return false;
    }
}

export function convertToSlug(name: string) {
  try {
      return name?.toLowerCase()
          .replace(/ /g, "-")
          .replace(/[^\w-]+/g, "");
  } catch {
      return '';
  }

}
 