import React from 'react';

// material-ui
import { styled } from '@mui/material/styles';
import { Dialog, IconButton, Typography } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

// assets
import CloseIcon from '@mui/icons-material/Close';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = ({ children, onClose, ...other }: DialogTitleProps) => (
    <DialogTitle sx={{ m: 0, pt: 1,  pl: 2,  pr: 2, pb: 1, 
        fontSize: '16px',
        color:  (theme) => theme.palette.warning.dark
    }} {...other}>
        {children}
        {onClose ? (
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 10,
                    top: 10,
                    color: (theme) => theme.palette.grey[500]
                }}
            >
                <CloseIcon />
            </IconButton>
        ) : null}
    </DialogTitle>
);

export default function KycAlert(props: { open: boolean, handleClose: () => void; }) {

    const handleClose = () => {
        props.handleClose();
    };

    return (
        <div>
            <BootstrapDialog disableEscapeKeyDown={true} fullWidth onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
                    System Alert: KYC Verifications Required
                </BootstrapDialogTitle>
                <DialogContent sx={{pt: 1,  pl: 2,  pr: 2, pb: 1}}>
                    <Typography>
                        Attention: KYC verifications are incomplete! Complete your KYC now to avoid service interruptions.
                    </Typography>
                    <Typography>
                        Go to Profile Sections and Open Kyc tab and update details. Please do'nt share data and otp with unknown sources.
                    </Typography>
                </DialogContent>
            </BootstrapDialog>
        </div>
    );
}
