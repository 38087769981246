import get from "lodash/get";

export function addUpdateProfileSuccessSelector(state: any) {
    let addUpdateProfileSuccess =  get(state, ["dsa", "profile" , "addUpdateProfileSuccess"]);
    if(addUpdateProfileSuccess) {
        return addUpdateProfileSuccess;
    }
    return false;
}

export function userProfileImageUploadSelector(state: any) {
    let addUpdateProfileSuccess =  get(state, ["dsa", "profile" , "userProfileImageUploadSuccess"]);
    if(addUpdateProfileSuccess) {
        return addUpdateProfileSuccess;
    }
    return false;
}

export function userProfileImageSelector(state: any) {
    let addUpdateProfileSuccess =  get(state, ["dsa", "profile" , "userProfileImage"]);
    if(addUpdateProfileSuccess) {
        return addUpdateProfileSuccess;
    }
    return false;
}

export function verifyGstDataSelector(state: any) {
    let verifyGstStatus =  get(state, ["dsa", "profile" , "verifyGstStatus"]);
    return verifyGstStatus;
}

export function verifyPanDataSelector(state: any) {
    let verifyPanStatus =  get(state, ["dsa", "profile" , "verifyPanStatus"]);
    return verifyPanStatus;
}

export function verifyAadhaarDataSelector(state: any) {
    let verifyAadhaarStatus =  get(state, ["dsa", "profile" , "verifyAadhaarStatus"]) || null;
    return verifyAadhaarStatus;
}

export function verifyBankDataSelector(state: any) {
    let verifyBankStatus =  get(state, ["dsa", "profile" , "verifyBankStatus"]);
    return verifyBankStatus;
}

export function verifyCINDataSelector(state: any) {
    let verifyCINStatus =  get(state, ["dsa", "profile" , "verifyCINStatus"]);
    return verifyCINStatus;
}