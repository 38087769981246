import get from "lodash/get";

export function notificationsListSelector(state: any) {
    return get(state, ["dsa", "notifications", "notificationsList"]) ?? [];
}

export function fetchAccountNotificationsAllCountSelector(state: any) {
    let notifications = get(state, ["dsa", "notifications", 'allCount']) || 0;
    return notifications;
}

export function fetchAccountNotificationsAllUnseenCountSelector(state: any) {
    let notifications = get(state, ["dsa", "notifications", 'unread']) || 0;
    return notifications;
}

export function fetchAlertNotificationsSelector(state: any) {
    return get(state, ["dsa", "notifications", 'alertList']) ?? [];
}

export function fetchAlertNotificationsCountSelector(state: any) {
    let notifications = get(state, ["dsa", "notifications", 'alertListCount']) || 0;
    return notifications;
}

export function fetchAlertNotificationsEditSelector(state: any) {
    let notifications = get(state, ["dsa", "notifications", 'editNotification']) || 0;
    return notifications;
}