import { createSlice } from '@reduxjs/toolkit';

const bankMisSlice = createSlice({
  name: 'bankMis',
  initialState: {
    bankMis: [],
    bankMisUnMatched: [],
    bankMisBunches: [],
    banks: [],
    products: [],
    productTypes: [],
    editBankMis: {},
    addUpdateBankMisSuccess: false,
    createBankMisSuccess: false,
    bankMisCount: 0,
    bankMisBunchCount: 0,
    queueTimeOfRecheck: null
  },
  reducers: {
    fetchBankMis(state, action) { 
      state.bankMis = action.payload;
    },
    queueTimeOfRecheckBankMis(state, action) { 
      state.queueTimeOfRecheck = action.payload;
    },
    fetchBankMisBankMisUnMatched(state, action) { 
      state.bankMisUnMatched = action.payload;
    },
    getEditBankMisById(state, action) { 
      state.editBankMis = action.payload;
    },
    fetchBankMisBanks(state, action) { 
      state.banks = action.payload;
    },
    fetchBankMisProducts(state, action) { 
      state.products = action.payload;
    },
    fetchBankMisProductType(state, action) { 
      state.productTypes = action.payload;
    },
    fetchBankMisBunches(state, action) { 
      state.bankMisBunches = action.payload;
    },
    fetchBankMisBunchesBanks(state, action) { 
      state.banks = action.payload;
    },
    fetchBankMisCount(state, action) { 
      state.bankMisCount = action.payload;
    },
    fetchBankMisBunchCount(state, action) { 
      state.bankMisBunchCount = action.payload;
    },
    setCreateChannelMisSuccess(state, action) { 
      state.createBankMisSuccess = action.payload;
    },
    addUpdateBankMisSuccess(state, action) { 
      state.addUpdateBankMisSuccess = action.payload;
    },
  },
});

export const { fetchBankMis, queueTimeOfRecheckBankMis, fetchBankMisBankMisUnMatched, getEditBankMisById, fetchBankMisProductType, fetchBankMisProducts, fetchBankMisBanks, fetchBankMisBunchesBanks, fetchBankMisCount, fetchBankMisBunches, fetchBankMisBunchCount,  setCreateChannelMisSuccess, addUpdateBankMisSuccess} = bankMisSlice.actions;

export default bankMisSlice.reducer;