import { createSlice } from '@reduxjs/toolkit';

const helpCenterSlice = createSlice({
  name: 'helpCenter',
  initialState: {
    category: [],
    categoryArticles: [],
    pubCategoryArticles: [],
    editArticle: null,
    editCategory: null,
    categoryCount: null,
    articlesCount: null,
    pubArticlesCount: null,
    addUpdateSuccess: false
  },
  reducers: {
    fetchCategory(state, action) { 
      state.category = action.payload;
    },
    fetchCategoryArticles(state, action) { 
      state.categoryArticles = action.payload;
    },
    fetchPublicCategoryArticles(state, action) { 
      state.pubCategoryArticles = action.payload;
    },
    fetchEditArticle(state, action) { 
      state.editArticle = action.payload;
    },
    fetchEditCategory(state, action) { 
      state.editCategory = action.payload;
    },
    fetchCategoryCount(state, action) { 
      state.categoryCount = action.payload;
    },
    fetchArticlesCount(state, action) { 
      state.articlesCount = action.payload;
    },
    fetchPublicArticlesCount(state, action) { 
      state.pubArticlesCount = action.payload ? action.payload: 0;
    },
    addUpdateArticleCategorySuccess(state, action) { 
      state.addUpdateSuccess = action.payload;
    },
  },
});

export const {fetchCategory, 
  fetchCategoryArticles, 
  fetchPublicCategoryArticles,
  fetchEditCategory,
  fetchPublicArticlesCount,
  fetchCategoryCount,
  fetchArticlesCount,
  addUpdateArticleCategorySuccess,
  fetchEditArticle } = helpCenterSlice.actions;

export default helpCenterSlice.reducer;