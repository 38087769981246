//@ts-ignore
import axios from 'utils/axios';
import { openSnackbar } from "store/slices/snackbar";
import {fetchAlertNotificationsCount, fetchAlertNotificationsList, fetchCountOfNotificationsActions, fetchNotificationById, fetchNotificationsList } from 'store/state/data-slices/dsa/notificationsSlice';


export function fetchNotificationsListApi() {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/dsa/notifications/list`,
        method: 'get'
      });
      if (response.data.success) {
        const responseData = response?.data?.notifications;
        const notificationsByType = responseData.reduce((acc:any, curr:any) => {
          if (!acc[curr.type]) {
            acc[curr.type] = []; 
          }
          acc[curr.type].push(curr); 
          return acc;
        }, {});
        if (responseData?.length > 0) {
          dispatch(fetchNotificationsList(notificationsByType));
          const other = responseData?.filter((d: any) => d.type === 'notifications');
          const unread = (other?.filter((d: any) => d.type === 'notifications' && !d?.isRead))?.length;
          dispatch(fetchCountOfNotificationsActions({ countAll: other?.length, unread: unread }));
        } else {
          dispatch(fetchCountOfNotificationsActions({ countAll: 0, unread: 0 }));
          dispatch(fetchNotificationsList([]));
        }

      }
    } catch (error: any) {
      if (error && error.message) {
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: error.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    }
  };
}

export function fetchAlertNotificationsListApi(body: any ) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/dsa/notifications/alert/list?search=${body.search ?? ''}&limit=${body.limit}&page=${body.page}`,
        method: 'get'
      });
      if (response.data.success) {
        const responseData = response?.data?.notifications;
        const notificationsByType = responseData.reduce((acc:any, curr:any) => {
          if (!acc[curr.type]) {
            acc[curr.type] = []; 
          }
          acc[curr.type].push(curr); 
          return acc;
        }, {});
        if (responseData?.length > 0) {
          dispatch(fetchAlertNotificationsList(notificationsByType));
          dispatch(fetchAlertNotificationsCount(response?.data?.count));
        } else {
          dispatch(fetchAlertNotificationsCount(0));
          dispatch(fetchAlertNotificationsList([]));
        }

      }
    } catch (error: any) {
      if (error && error.message) {
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: error.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    }
  };
}

export function getAlertNotificationByIdApi(body: {id: number} ) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/dsa/notifications/alert/${body.id}`,
        method: 'post'
      });
      if (response.data.success) {
        const responseData = response?.data?.notification;
        if (responseData) {
          dispatch(fetchNotificationById(responseData));
        } else {
          dispatch(fetchNotificationById(null));
        }

      }
    } catch (error: any) {
      if (error && error.message) {
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: error.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    }
  };
}

export function updateNotificationsApi(body: any) {
  return async (dispatch: any) => {
    try {
      const id = body?.id;
      delete body?.id;
      const response = await axios({
        url: `/dsa/notifications/alert/${id}`,
        method: 'post',
        data: body
      });
      if (response?.data?.success) {
        dispatch(fetchAlertNotificationsListApi({limit: 100, page: 1, search: '' }));
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: response?.data?.message,
            variant: 'alert',
            alert: {
              color: 'success'
            }
          }));
      } else {
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: response?.data?.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    } catch (error: any) {
      dispatch(
        openSnackbar({
          open: true,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          message: error?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        }));
    }
  };
}

export function createNotificationsApi(body: any) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/dsa/notifications/create`,
        method: 'post',
        data: body
      });
      if (response?.data?.success) {
        dispatch(fetchNotificationsListApi());
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: response?.data?.message,
            variant: 'alert',
            alert: {
              color: 'success'
            }
          }));
      } else {
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: response?.data?.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    } catch (error: any) {
      //dispatch(fetchUserProfileAction({}));
      dispatch(
        openSnackbar({
          open: true,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          message: error?.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        }));
    }
  };
}

export function deleteNotificationsAlertApi(body: { ids: any[] }) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/dsa/notifications/alert`,
        method: 'delete',
        data: body
      });
      if (response.data.success) {
        dispatch(fetchAlertNotificationsListApi({limit: 100, page: 1, search: '' }));
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: response.data.message,
            variant: 'alert',
            alert: {
              color: 'success'
            }
          }));
      }
    } catch (error: any) {
      if (error && error.message) {
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: error.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    }
  };
}
