import { Card, CardContent, Typography, Grid, Link, styled, useTheme } from "@mui/material";
import { Box, Container } from "@mui/system";
import HeaderSection from "./HeaderSection";
import FooterSection from "views/pages/landing/FooterSection";
import { useEffect } from "react";
import { useDispatch, useSelector } from "store";
import { getPublicArticles } from "store/state/data-layer/admin/helpCenter";
import { pubCategoryArticlesSelector } from "store/state/adminSelectors/helpSelectors";

const SectionWrapper = styled('div')({
    paddingTop: 100,
    paddingBottom: 100
});

const InfoCard = ({ title, slug, description, links }: any) => {
    return (
        <Card sx={{ borderRadius: '15px', minHeight: '100%', position: 'relative', border: '1px solid', borderColor: '#cfe4f5', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', height: '100%' }}>
            <CardContent>
                <Typography variant="h3" fontWeight="bold" gutterBottom>
                    {title}
                </Typography>
                <Typography variant="subtitle1" gutterBottom sx={{
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis'
                }}>
                    {description}
                </Typography>
                {links.map((link: any, index: number) => (
                    <Typography key={index} variant="body1" color="text.primary">
                        <Link href={`/help/${slug}/${link?.slug}`} underline="hover" sx={{ textDecoration: 'underline' }} textOverflow={'ellipsis'} textTransform={'capitalize'} color="text.secondary">
                            {link?.title}
                        </Link>
                    </Typography>
                ))}
                <br></br>
                <br></br>
                <Link href={`/help/${slug}`} underline="hover" sx={{position: 'absolute', bottom: '20px', display: 'block', mt: 2 }}>
                    See all
                </Link>
            </CardContent>
        </Card>
    );
};

const CardsSection = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const cardData = useSelector(pubCategoryArticlesSelector);

    useEffect(() => {
        dispatch(getPublicArticles({ limit: 10, page: 1, search: '' }));
    }, [dispatch]);

    return (
        <Box sx={{ py: 4 }}>
            <HeaderSection id="home" />
            <Container maxWidth="lg" sx={{ mt: -12, mb: 6 }}>
                <Grid container spacing={4} justifyContent={'center'}>
                    {cardData?.map((card: any, index: number) => (
                        <Grid item xs={12} sm={6} key={index}>
                            <InfoCard slug={card?.slug} title={card?.name} description={card?.description} links={card?.articles || []} />
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <SectionWrapper sx={{ bgcolor: theme.palette.mode === 'dark' ? 'background.default' : 'dark.900', pb: 0 }}>
                <FooterSection />
            </SectionWrapper>
        </Box>
    );
};

export default CardsSection;