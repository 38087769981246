import { createSlice } from '@reduxjs/toolkit';

const channelOnboardingsSlice = createSlice({
  name: 'channelQuestions',
  initialState: {
    questions: [],
    requestUserProfile: {},
    questionsCount: 0,
    isAgreementDataSaved: null,
    questionsUpdateSuccess: false,
    verifyAgreementAadhaarStatus: false,
    aadhaarAgreementAPICall: false,
    isPdfSentOnMailAPICall: false,
  },
  reducers: {
    fetchChannelOnboardingQuestions(state, action) { 
      state.questions = action.payload;
    },
    questionsCount(state, action) { 
      state.questionsCount = action.payload;
    },
    questionsUpdateSuccessAction(state, action) { 
      state.questionsUpdateSuccess = action.payload;
    },
    isAgreementDataSavedSuccessAction(state, action) { 
      state.isAgreementDataSaved = action.payload;
    },
    requestUserProfileAction(state, action) { 
      state.requestUserProfile = action.payload;
    },
    verifyAgreementAadhaarStatus(state, action) {
      state.verifyAgreementAadhaarStatus = action.payload;
    },
    aadhaarAgreementAPICallErrorAction(state, action) {
      state.aadhaarAgreementAPICall = action.payload;
    },
    isPdfSentOnMailAPICalling(state, action) {
      state.isPdfSentOnMailAPICall = action.payload;
    }
    
  },
});

export const { fetchChannelOnboardingQuestions, questionsCount, aadhaarAgreementAPICallErrorAction,
  questionsUpdateSuccessAction, requestUserProfileAction, isAgreementDataSavedSuccessAction, verifyAgreementAadhaarStatus, 
  isPdfSentOnMailAPICalling,
 } = channelOnboardingsSlice.actions;

export default channelOnboardingsSlice.reducer;