import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

const Company = Loadable(lazy(() => import('views/application/dsa/company')));
const AddCompany = Loadable(lazy(() => import('views/application/dsa/company/AddCompany')));
const ViewCompanyProfile = Loadable(lazy(() => import('views/application/dsa/company/profile')));
const FieldVariables = Loadable(lazy(() => import('views/application/dsa/channel/fieldVariables')));

export const CompanyRoutes = [
    {
        path: '/company/add',
        element: <AddCompany/>
    },
    {
        path: '/company/list',
        element: <Company/>
    },
    {
        path: '/company/view/:id',
        element: <ViewCompanyProfile/>
    },
    {
        path: '/channel/variables',
        element: <FieldVariables/>
    },

];
