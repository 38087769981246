import { createSlice } from '@reduxjs/toolkit';

const bankMasterSlice = createSlice({
  name: 'bankMasters',
  initialState: {
    bankMasters: [],
    banksOfAdmin: [],
    dsaBanks: [],
    bankMastersCount: 0,
    adminBankMastersCount: 0,
    dsaBankCount: 0,
    addUpdateBankSuccess: false,

  },
  reducers: {
    fetchBankMasters(state, action) { 
      state.bankMasters = action.payload;
    },
    fetchDsaBankMasters(state, action) { 
      state.dsaBanks = action.payload;
    },
    fetchAllAdminBankMasters(state, action) { 
      state.banksOfAdmin = action.payload;
    },
    bankMastersCount(state, action) { 
      state.bankMastersCount = action.payload ? action.payload: 0;
    },
    adminBankMastersCount(state, action) { 
      state.adminBankMastersCount = action.payload ? action.payload: 0;
    },
    dsaBankCount(state, action) { 
      state.dsaBankCount = action.payload ? action.payload: 0;
    },
    addUpdateBankSuccess(state, action) { 
      state.addUpdateBankSuccess = action.payload;
    },
  },
});

export const { fetchBankMasters, fetchDsaBankMasters,
   bankMastersCount , addUpdateBankSuccess, dsaBankCount, adminBankMastersCount, 
   fetchAllAdminBankMasters} = bankMasterSlice.actions;

export default bankMasterSlice.reducer;