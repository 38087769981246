// third-party
import { FormattedMessage } from 'react-intl';

import { NavItemType } from 'types';

// assets
import {
    IconApps, IconUserCheck, IconBasket, IconMessages, IconLayoutKanban, IconMail,
    IconCalendar, IconNfc, IconSettings, IconClipboardPlus,
    IconBuildingCommunity, IconBuildingBank, IconClipboardList, IconBook, IconBooks, IconGizmo, IconUserPlus, IconUsers
} from '@tabler/icons';

// constant
const icons = {
    IconApps,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconBuildingCommunity,
    IconBuildingBank,
    IconClipboardList,
    IconBook,
    IconBooks,
    IconGizmo,
    IconUserPlus,
    IconUsers, IconSettings, IconClipboardPlus,
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const channel: NavItemType = {
    id: 'dsaChannel',
    title: 'Application',
    icon: icons.IconApps,
    type: 'group',
    isDevMode: false,
    children: [
        {
            id: 'daily-sales',
            title: 'Daily Sales Tracker',
            type: 'item',
            icon: icons.IconUserCheck,
            url: '/daily-sales'
            //disabled: true,
        },
        {
            id: 'beneficiary',
            title: 'Beneficiary',
            type: 'collapse',
            icon: icons.IconGizmo,
            disabled: true,
            children: [
                {
                    id: 'add-beneficiary',
                    title: 'Add Beneficiary',
                    type: 'item',
                    url: '/channel/add-beneficiary'
                },
                {
                    id: 'beneficiary',
                    title: 'View Beneficiary',
                    type: 'item',
                    url: '/channel/beneficiary'
                },
            ]
        },
        {
            id: 'mis-uploader',
            title: 'MIS Uploader',
            type: 'collapse',
            disabled: true,
            icon: icons.IconBuildingBank,
            children: [
                {
                    id: 'add-channel-mis',
                    title: 'Add Channel MIS',
                    type: 'item',
                    url: '/mis-uploader/add-channel-mis'
                },
                {
                    id: 'channel-mis',
                    title: 'Channel MIS List',
                    type: 'item',
                    url: '/mis-uploader/channel-mis'
                },
            ]
        },
        {
            id: 'mis-review',
            title: 'MIS Review',
            type: 'collapse',
            disabled: true,
            icon: icons.IconBuildingBank,
            children: [
                {
                    id: 'review-mis',
                    title: 'Review Channel MIS',
                    type: 'item',
                    url: '/mis-uploader/review-mis'
                },
            ]
        },
        {
            id: 'payout-manager',
            title: 'Payout Manager',
            type: 'collapse',
            disabled: true,
            icon: icons.IconBuildingBank,
            children: [
                {
                    id: 'payout-list',
                    title: 'Channel Payout List',
                    type: 'item',
                    url: '/payout-list'
                },
            ]
        },
        // {
        //     id: 'banks',
        //     title: 'Bank',
        //     type: 'collapse',
        //     disabled: true,
        //     icon: icons.IconBuildingBank,
        //     children: [
        //         {
        //             id: 'bank-code',
        //             title: 'Code Master',
        //             type: 'collapse',
        //             disabled: true,
        //             children: [
        //                 {
        //                     id: 'code',
        //                     title: 'View Banks Codes',
        //                     type: 'item',
        //                     url: '/bank/code'
        //                 },
        //             ]
        //         },
        //         {
        //             id: 'bank-structure-master',
        //             title: 'Payout Structure',
        //             type: 'collapse',
        //             children: [
        //                 {
        //                     id: 'add-bank-structure',
        //                     title: 'Add Structure',
        //                     type: 'item',
        //                     url: '/payout-manager/structure/add'
        //                 },
        //                 {
        //                     id: 'view-bank-structure',
        //                     title: 'View Structures',
        //                     type: 'item',
        //                     url: '/payout-manager/structure'
        //                 },
        //             ]
        //         },
        //         {
        //             id: 'policies',
        //             title: 'Policies',
        //             type: 'collapse',
        //             disabled: true,
        //             children: [
        //                 {
        //                     id: 'list',
        //                     title: 'List Policies',
        //                     type: 'item',
        //                     url: '/policies/list'
        //                 }
        //             ]
        //         },
        //         {
        //             id: 'contact-list',
        //             title: 'Bank Contact List',
        //             type: 'item',
        //             url: '/bank-contact/list'

        //         },

        //     ]
        // },
        {
            id: 'applications',
            title: 'Applications',
            type: 'collapse',
            disabled: true,
            icon: icons.IconClipboardPlus,
            children: [
                {
                    id: 'add-application',
                    title: 'Add Application',
                    type: 'item',
                    //url: '/channel/applications/add-application'
                },
                {
                    id: 'list-application',
                    title: 'Applications List',
                    type: 'item',
                    //url: '/channel/applications/list-application'

                },

            ]
        },
        {
            id: 'reports',
            title: 'Reports',
            type: 'collapse',
            disabled: true,
            icon: icons.IconBuildingBank,
            children: [
                {
                    id: 'reports-list',
                    title: 'Reports List',
                    type: 'item',
                    //url: '/reports/list'
                },
            ]
        },
        {
            id: 'users',
            title: 'Users',
            type: 'collapse',
            icon: icons.IconUsers,
            disabled: false,
            children: [
                {
                    id: 'add-user',
                    title: 'Add User',
                    type: 'item',
                    url: '/user/add'
                },
                {
                    id: 'list-user',
                    title: 'User List',
                    type: 'item',
                    url: '/user/list'
                },
                {
                    id: 'roles-permissions',
                    title: 'Roles & Permissions',
                    type: 'collapse',
                    children: [
                        {
                            id: 'add-role',
                            title: 'Add Role',
                            type: 'item',
                            url: '/user/role/add'
                        },
                        {
                            id: 'list-role',
                            title: 'Role List',
                            type: 'item',
                            url: '/user/role/list'
                        },
                    ]
                }

            ]
        },
        {
            id: 'setting',
            title: 'Settings',
            type: 'collapse',
            icon: icons.IconSettings,
            disabled: false,
            children: [
                {
                    id: 'notification',
                    title: 'Notifications',
                    type: 'collapse',
                    disabled: true,
                    //icon: icons.IconBuildingBank,
                    children: [
                        {
                            id: 'view-notification',
                            title: 'View Notifications',
                            type: 'item',
                            //url: '/notification'
                        },
                    ]
                },

            ]
        },
    ]
};

export default channel;





