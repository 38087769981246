import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
const AuthLogin = Loadable(lazy(() => import('views/application/admin/pages/auth/AdminLogin')));
const AuthRegister = Loadable(lazy(() => import('views/application/admin/pages/auth/Register1')));
const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));
const PageNotFound = Loadable(lazy(() => import('views/pages/maintenance/PageNotFound')));

// ==============================|| AUTH ROUTING ||============================== //

const AdminLoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/backend/login',
            element: <AuthLogin />
        },
        {
            path: '/dsa/register',
            element: <AuthRegister />
        },
        {
            path: '/',
            element: <PageNotFound />
        },
        {
            path: '/*',
            element: <PageNotFound />
        }
    ]
};

export default AdminLoginRoutes;
