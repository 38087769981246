import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';


const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardAnalytics = Loadable(lazy(() => import('views/dashboard/Analytics')));

const Company = Loadable(lazy(() => import('views/application/admin/company')));
const AddCompany = Loadable(lazy(() => import('views/application/admin/company/AddCompany')));
const EditCompany = Loadable(lazy(() => import('views/application/admin/company/EditCompany')));

const DSA = Loadable(lazy(() => import('views/application/admin/dsa')));
const AddDsa = Loadable(lazy(() => import('views/application/admin/dsa/AddDsa')));
const EditDsa = Loadable(lazy(() => import('views/application/admin/dsa/EditDsa')));

const UserDsa = Loadable(lazy(() => import('views/application/admin/dsa/users')));
const AddUserDsa = Loadable(lazy(() => import('views/application/admin/dsa/users/AddUser')));
const EditUserDsa = Loadable(lazy(() => import('views/application/admin/dsa/users/EditUser')));

const UserCompany = Loadable(lazy(() => import('views/application/admin/company/users')));
const AddUserCompany = Loadable(lazy(() => import('views/application/admin/company/users/AddUser')));
const EditUserCompany = Loadable(lazy(() => import('views/application/admin/company/users/EditUser')));

const UserChannel = Loadable(lazy(() => import('views/application/admin/channel/users')));
const AddUserChannel = Loadable(lazy(() => import('views/application/admin/channel/users/AddUser')));
const EditUserChannel = Loadable(lazy(() => import('views/application/admin/channel/users/EditUser')));


const Product = Loadable(lazy(() => import('views/application/admin/product')));
const AddProduct = Loadable(lazy(() => import('views/application/admin/product/AddProduct')));
const EditProduct = Loadable(lazy(() => import('views/application/admin/product/EditProduct')));

const ProductTypes = Loadable(lazy(() => import('views/application/admin/product/productTypes')));
const AddProductTypes = Loadable(lazy(() => import('views/application/admin/product/productTypes/AddProductTypes')));
const EditProductTypes = Loadable(lazy(() => import('views/application/admin/product/productTypes/EditProductTypes')));

const Bank = Loadable(lazy(() => import('views/application/admin/bank')));
const AddBank = Loadable(lazy(() => import('views/application/admin/bank/AddBank')));
const EditBank = Loadable(lazy(() => import('views/application/admin/bank/EditBank')));

const Channel = Loadable(lazy(() => import('views/application/admin/channel')));
const AddChannel = Loadable(lazy(() => import('views/application/admin/channel/AddChannel')));
const EditChannel = Loadable(lazy(() => import('views/application/admin/channel/EditChannel')));
const Keywords = Loadable(lazy(() => import('views/application/admin/channel/Keywords')));
const AddKeyword = Loadable(lazy(() => import('views/application/admin/channel/AddKeyword')));

const Users = Loadable(lazy(() => import('views/application/admin/users')));
const AddUser = Loadable(lazy(() => import('views/application/admin/users/AddUser')));
const EditUser = Loadable(lazy(() => import('views/application/admin/users/EditUser')));
const RoleAndPermissions = Loadable(lazy(() => import('views/application/admin/roles')));
const AddRole = Loadable(lazy(() => import('views/application/admin/roles/AddRole')));
const EditRole = Loadable(lazy(() => import('views/application/admin/roles/EditRole')));
const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));

const AddArticle = Loadable(lazy(() => import('views/application/admin/helpCenter/AddArticles')));
const KnowledgeBase = Loadable(lazy(() => import('views/application/admin/helpCenter')));
const Category = Loadable(lazy(() => import('views/application/admin/helpCenter/category')));
const AddCategory = Loadable(lazy(() => import('views/application/admin/helpCenter/category/AddCategory')));

// ==============================|| MAIN ROUTING ||============================== //

const AdminRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <DashboardDefault />,           
        },
        {
            path: '/dashboard/analytics',
            element: <DashboardAnalytics />
        },
        {
            path: '/help-center/list',
            element: <KnowledgeBase/>
        },
        {
            path: '/help-center/add',
            element: <AddArticle />
        },
        {
            path: '/help-center/edit/:id',
            element: <AddArticle />
        },
        {
            path: '/help-center/category/add',
            element: <AddCategory/>
        },
        {
            path: '/help-center/category/edit/:id',
            element: <AddCategory/>
        },
        {
            path: '/help-center/category/list',
            element: <Category/>
        },
        {
            path: '/dsa/list',
            element: <DSA />
        },
        {
            path: '/dsa/add',
            element: <AddDsa />
        },
        {
            path: '/dsa/edit/:id',
            element: <EditDsa/>
        },
        {
            path: '/dsa/user/list',
            element: <UserDsa />
        },
        {
            path: '/dsa/user/add',
            element: <AddUserDsa />
        },
        {
            path: '/dsa/user/edit/:id',
            element: <EditUserDsa />
        },
        {
            path: '/company/list',
            element: <Company/>
        },
        {
            path: '/company/add',
            element: <AddCompany />
        },
        {
            path: '/company/edit/:id',
            element: <EditCompany/>
        },
        {
            path: '/company/user/list',
            element: <UserCompany />
        },
        {
            path: '/company/user/add',
            element: <AddUserCompany />
        },
        {
            path: '/company/user/edit/:id',
            element: <EditUserCompany/>
        },
        {
            path: '/bank/list',
            element: <Bank />
        },
        {
            path: '/bank/add',
            element: <AddBank />
        },
        {
            path: '/bank/edit/:id',
            element: <EditBank />
        },
        {
            path: '/product/list',
            element: <Product />
        },
        {
            path: '/product/add',
            element: <AddProduct />
        },
        {
            path: '/product/edit/:id',
            element: <EditProduct />
        },
        {
            path: '/product-types/list',
            element: <ProductTypes />
        },
        {
            path: '/product-types/add',
            element: <AddProductTypes />
        },
        {
            path: '/product-types/edit/:id',
            element: <EditProductTypes/>
        },
        {
            path: '/channel/list',
            element: <Channel />
        },
        {
            path: '/channel/add',
            element: <AddChannel />
        },
        {
            path: '/channel/edit/:id',
            element: <EditChannel/>
        },
        {
            path: '/channel/keywords',
            element: <Keywords/>
        },
        {
            path: '/channel/keywords/add',
            element: <AddKeyword/>
        },
        {
            path: '/channel/user/list',
            element: <UserChannel />
        },
        {
            path: '/channel/user/add',
            element: <AddUserChannel />
        },
        {
            path: '/channel/user/edit/:id',
            element: <EditUserChannel/>
        },
        {
            path: '/user/list',
            element: <Users />
        },
        {
            path: '/user/add',
            element: <AddUser />
        },
        {
            path: '/user/edit/:id',
            element: <EditUser />
        },
        {
            path: '/user/role/list',
            element: <RoleAndPermissions />
        },
        {
            path: '/user/role',
            element: <RoleAndPermissions />
        },
        {
            path: '/user/role/add',
            element: <AddRole/>
        }
        ,
        {
            path: '/user/role/edit/:id',
            element: <EditRole/>
        },
        {
            path: '/*',
            element: <MaintenanceError/>
        }


    ],

};

export default AdminRoutes;
