import React, { useMemo } from 'react';
import { Typography, Box, List, ListItem, ListItemText, Paper, useTheme, styled, Divider } from '@mui/material';
import useConfig from 'hooks/useConfig';

import BgDark from 'assets/images/landing/bg-hero-block-dark.png';
import BgLight from 'assets/images/landing/bg-hero-block-light.png';

interface FaqContentProps {
    content: any;
}


const HeaderAnimationImage = styled('img')({
    maxWidth: '100%',
    filter: 'drop-shadow(0px 0px 50px rgb(33 150 243 / 30%))'
});

function HTMLRenderer({ htmlContent }: any) {
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
}

const FaqContent: React.FC<FaqContentProps> = ({ content }) => {
    const theme = useTheme();
    const { rtlLayout } = useConfig();

    return (
        <Box>

            <Typography variant="h1" gutterBottom>
                {content?.title}
            </Typography>
            <Typography variant="body1" gutterBottom>
                {content?.description}
            </Typography>
            <Divider sx={{mt: '10px', mb: '20px'}}/>
            
            <Typography variant="body1" paragraph>
                {content?.description}
            </Typography>

            <HTMLRenderer htmlContent={content?.content} />
        </Box>
    );
};

export default FaqContent;
