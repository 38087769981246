import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'users',
  initialState: {
    error: null,
    users: {},
    userMetaVerifyCompleted: null,
    usersCount: null,
    addUpdateSuccess: false,
    adminAuthAPISuccess: false
  },
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    userMetaVerifyCompletedAction(state, action) {
      state.userMetaVerifyCompleted = action.payload;
    },
    fetchUsers(state, action) { 
      state.users = action.payload;
    },
    usersCountReducer(state, action) { 
      state.usersCount = action.payload;
    },
    addUpdateSuccessAction(state, action) { 
      state.addUpdateSuccess = action.payload;
    },
    adminAuthAPISuccessAction(state, action) { 
      state.adminAuthAPISuccess = action.payload;
    },
  },
});

export const { fetchUsers, usersCountReducer, userMetaVerifyCompletedAction, addUpdateSuccessAction, adminAuthAPISuccessAction, hasError} = userSlice.actions;

export default userSlice.reducer;