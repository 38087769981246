import { addUpdateArticleCategorySuccess, fetchArticlesCount, fetchCategory, fetchCategoryArticles, fetchCategoryCount, fetchEditArticle, fetchEditCategory, fetchPublicCategoryArticles } from "../../data-slices/admin/helpCenterSlice";
import axios from 'utils/axios';
import { openSnackbar } from "store/slices/snackbar";

export function fetchArticlesAdminApi(body: { limit: number | 20, page: number | 1, search: string | '' }) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/admin/help/articles?search=${body.search ?? ''}&limit=${body.limit}&page=${body.page}`,
        method: 'get',

      });
      if (response.data.success) {
        dispatch(fetchCategoryArticles(response.data?.articles));
        dispatch(fetchArticlesCount(response.data.count));
      } else {
        dispatch(fetchCategoryArticles([]));
        dispatch(fetchArticlesCount(0));
      }

    } catch (error: any) {
      dispatch(fetchCategoryArticles([]));
      dispatch(fetchArticlesCount(0));
      if (error && error.message) {
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: error.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    }
  };
}

export function fetchEditArticleAdminApi(body: { id: number }) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/admin/help/articles/${body?.id}`,
        method: 'get',

      });
      if (response.data.success) {
        dispatch(fetchEditArticle(response.data?.data));
      } else {
        dispatch(fetchEditArticle(null));
      }

    } catch (error: any) {
      dispatch(fetchEditArticle(null));
      if (error && error.message) {
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: error.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    }
  };
}

export function fetchCategoryAdminApi(body: { limit: number | 20, page: number | 1, search: string | '' }) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/admin/help/categories?search=${body.search ?? ''}&limit=${body.limit}&page=${body.page}`,
        method: 'get',

      });
      if (response.data.success) {
        dispatch(fetchCategory(response.data?.categories));
        dispatch(fetchCategoryCount(response?.data?.count));
      } else {
        dispatch(fetchCategory([]));
        dispatch(fetchCategoryCount(0));
      }

    } catch (error: any) {
      dispatch(fetchCategory([]));
      dispatch(fetchCategoryCount(0));
      if (error && error.message) {
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: error.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    }
  };
}

export function fetchEditCategoryAdminApi(body: { id: number }) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/admin/help/category/${body?.id}`,
        method: 'get',

      });
      if (response.data.success) {
        dispatch(fetchEditCategory(response.data?.data));
      } else {
        dispatch(fetchEditCategory(null));
      }

    } catch (error: any) {
      dispatch(fetchEditCategory(null));
      if (error && error.message) {
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: error.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    }
  };
}


export function createArticleApi(body: any) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/admin/help/articles/create`,
        method: 'post',
        data: body

      });
      if (response.data.success) {
        //dispatch(fetchArticlesAdminApi({ limit: 0, page: 0, search: '' }));
        dispatch(addUpdateArticleCategorySuccess(response?.data));
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: response.data.message,
            variant: 'alert',
            alert: {
              color: 'success'
            }
          }));
      }
    } catch (error: any) {
      if (error && error.message) {
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: error.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    }
  };
}

export function createCategoryApi(body: any) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/admin/help/category/create`,
        method: 'post',
        data: body

      });
      if (response.data.success) {
        dispatch(fetchCategoryAdminApi({ limit: 0, page: 0, search: '' }));
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: response.data.message,
            variant: 'alert',
            alert: {
              color: 'success'
            }
          }));
      }
    } catch (error: any) {
      if (error && error.message) {
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: error.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    }
  };
}

export function bulkActionsApi(body: { action: string, recordType: string, ids: any[] }) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/admin/help/bulk`,
        method: 'post',
        data: body
      });
      if (response.data.success) {
        dispatch(fetchArticlesAdminApi({ limit: 0, page: 0, search: '' }));
        dispatch(fetchCategoryAdminApi({ limit: 0, page: 0, search: '' }));
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: response.data.message,
            variant: 'alert',
            alert: {
              color: 'success'
            }
          }));
      }
    } catch (error: any) {
      if (error && error.message) {
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: error.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    }
  };
}

export function updateHelpArticleApi(body: any) {
  return async (dispatch: any) => {
    try {
      const id = body.id;
      if (body?.id) delete body?.id;
      const response = await axios({
        url: `/admin/help/articles/${id}`,
        method: 'put',
        data: body

      });
      if (response.data.success) {
        dispatch(fetchEditArticleAdminApi({ id }));
        dispatch(addUpdateArticleCategorySuccess(response?.data));
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: response.data.message,
            variant: 'alert',
            alert: {
              color: 'success'
            }
          }));
      }
    } catch (error: any) {
      if (error && error.message) {
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: error.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    }
  };
}

export function updateHelpCategoryApi(body: any) {
  return async (dispatch: any) => {
    try {
      const id = body?.id;
      const response = await axios({
        url: `/admin/help/category/${id}`,
        method: 'put',
        data: body

      });
      if (response.data.success) {
        dispatch(addUpdateArticleCategorySuccess(response.data.success));
        dispatch(fetchCategoryAdminApi({ limit: 0, page: 0, search: '' }));
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: response.data.message,
            variant: 'alert',
            alert: {
              color: 'success'
            }
          }));
      }
    } catch (error: any) {
      if (error && error.message) {
        dispatch(
          openSnackbar({
            open: true,
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
            message: error.message,
            variant: 'alert',
            alert: {
              color: 'error'
            }
          }));
      }
    }
  };
}


export function updateProfileImageApi(body: any) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/dsa/upload`,
        method: 'post',
        data: body

      });
      if (response.data.success) {
        //dispatch(fetchUserProfileApi());
      }
      dispatch(
        openSnackbar({
          open: true,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          message: response.data.message,
          variant: 'alert',
          alert: {
            color: response.data.success ? 'success' : 'error'
          }
        }));
      //dispatch(userProfileImageUploadAction(true));
    } catch (error: any) {
      // dispatch(userProfileImageUploadAction(true));
      dispatch(
        openSnackbar({
          open: true,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          message: error.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        }));
    }
  };
}

export function getPublicArticles(body: any) {
  return async (dispatch: any) => {
    try {
      const response = await axios({
        url: `/admin/public/articles?search=${body.search ?? ''}&limit=${body.limit}&page=${body.page}${body?.category ? `&category=${body.category}` : ''}`,
        method: 'get',
      });
      if (response.data.success) {
        dispatch(fetchPublicCategoryArticles(response.data?.data?.categories));
      }
    } catch (error: any) {
      dispatch(
        openSnackbar({
          open: true,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
          message: error.message,
          variant: 'alert',
          alert: {
            color: 'error'
          }
        }));
    }
  };
}
// export function getProfileImageApi(body: any) {
//   return async (dispatch: any) => {
//     try {
//       const response = await axios({
//         url: `/dsa/profile/image/profile/${body.id}`,
//         method: 'get',
//         data: body
//       });
//       if (response.data) {
//         dispatch(userProfileImageActions(response.data));
//       } else {
//         dispatch(userProfileImageActions(null));
//       }
//     } catch (error: any) {
//       dispatch(userProfileImageActions(null));
//     }
//   };
// }

