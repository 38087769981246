// third-party
import { combineReducers } from 'redux';
import snackbarReducer from './slices/snackbar';
import userReducer from './slices/user';
import menuReducer from './slices/menu';
import admin from './state/reducer';
import dsa from './state/reducerDsa';
import accountReducer from './state/data-slices/accountSlice';
import { persistReducer, persistStore } from 'redux-persist';

// project imports
import storage from 'redux-persist/lib/storage';

// ==============================|| REDUX - MAIN STORE ||============================== //

const persistConfig =  {
    key: 'root',
    storage,
    keyPrefix: process.env.REACT_APP_PERSISTER_KEY ? process.env.REACT_APP_PERSISTER_KEY+'-' : 'winpro-'
}

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    admin,
    dsa,
    account: persistReducer(persistConfig, accountReducer),
    snackbar: snackbarReducer,
    user: userReducer,
    menu: menuReducer
});

export default reducer;
