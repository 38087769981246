import { createSlice } from '@reduxjs/toolkit';

const applicationSlice = createSlice({
  name: 'application',
  initialState: {
    applications: {},
    applicationProfile: [],
    currentApplicantData: {},
    documentPreview: null,
    addUpdateApplicationSuccess: false,
    applicationCount: 0,
    verifyGstStatus: null,
    verifyPanStatus: null,
    verifyBankStatus: null,
    verifyCINStatus: null,
  },
  reducers: {
    fetchApplications(state, action) { 
      state.applications = action.payload;
    },
    fetchApplicationProfile(state, action) { 
      state.applicationProfile = action.payload;
    },
    fetchApplicationCount(state, action) { 
      state.applicationCount = action.payload;
    },
    
    addUpdateApplicationSuccess(state, action) { 
      state.addUpdateApplicationSuccess = action.payload;
    },

    saveCurrentApplicantData(state, action) { 
      state.currentApplicantData = action.payload;
    },

    verifyGstStatus(state, action) {
      state.verifyGstStatus = action.payload;
    },

    verifyBankStatus(state, action) {
      state.verifyBankStatus = action.payload;
    },

    verifyPanStatus(state, action) {
      state.verifyPanStatus = action.payload;
    },
    verifyCINStatus(state, action) {
      state.verifyCINStatus = action.payload;
    },

    documentPreview(state, action) { 
      state.documentPreview = action.payload;
    },
  
  },
});

export const { 
  fetchApplications, fetchApplicationCount, fetchApplicationProfile, addUpdateApplicationSuccess,
  saveCurrentApplicantData,
  verifyGstStatus, 
  verifyPanStatus, 
  verifyBankStatus, 
  verifyCINStatus,
  documentPreview,
 } = applicationSlice.actions;

export default applicationSlice.reducer;