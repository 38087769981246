import { combineReducers } from 'redux';
import companyMasterSliceReducer from './data-slices/dsa/companyMasterSlice';
import channelMasterSliceReducer from './data-slices/dsa/channelMasterSlice';
import bankMasterSliceReducer from './data-slices/dsa/bankMasterSlice';
import productsMasterSliceReducer from './data-slices/dsa/productMasterSlice';
import profileReducer from './data-slices/dsa/profileSlice';
import roleReducer from './data-slices/dsa/roleSlice';
import usersReducer from './data-slices/dsa/userSlice';
import applicationReducer from './data-slices/dsa/applicationSlice';
import misCheckerReducer from './data-slices/dsa/misCheckerSlice';
import misMakerReducer from './data-slices/dsa/misMakerSlice';
import channelOnboardingsSliceReducer from './data-slices/dsa/channelOnboardingsSlice';
import companyVariableSliceReducer from './data-slices/dsa/channelVariableSlice';
import companyAgreementSliceReducer from './data-slices/dsa/channelAgreementSlice';
import beneficiarySliceReducer from './data-slices/dsa/beneficiarySlice';
import dailyTaskSliceReducer from './data-slices/dsa/dailyTaskSlice';
import channelCategorySliceReducer from './data-slices/dsa/channelCategorySlice';
import companyBankCodeSliceReducer from './data-slices/dsa/companyBankCodeSlice';
import payoutStructureSliceReducer from './data-slices/dsa/payoutStructureSlice';
import bankMisStructureSliceSliceReducer from './data-slices/dsa/bankMisStructureSlice';
import accountSettingsSliceReducer from './data-slices/dsa/accountSlice';
import misPayoutSliceReducer from './data-slices/dsa/misPayoutSlice';
import notificationsSliceReducer from './data-slices/dsa/notificationsSlice';
import bankMisSliceReducer from './data-slices/dsa/bankMisSlice';
import misDsaCheckerReducer from './data-slices/dsa/misDsaCheckerSlice';

const dsa = combineReducers({
    accountSettings: accountSettingsSliceReducer,
    profile: profileReducer,
    task: dailyTaskSliceReducer,
    category: channelCategorySliceReducer,
    payoutStructure: payoutStructureSliceReducer,
    bankMisStructure: bankMisStructureSliceSliceReducer,
    bankCode: companyBankCodeSliceReducer,
    companyMasters: companyMasterSliceReducer,
    beneficiary: beneficiarySliceReducer,
    channelMasters: channelMasterSliceReducer,
    channelAgreements: companyAgreementSliceReducer,
    channelQuestions: channelOnboardingsSliceReducer,
    bankMasters: bankMasterSliceReducer,
    productMasters: productsMasterSliceReducer,
    roles: roleReducer,
    users: usersReducer,
    channelVariables: companyVariableSliceReducer,
    application: applicationReducer,
    misChecker: misCheckerReducer,
    misMaker: misMakerReducer,
    misPayout: misPayoutSliceReducer,
    notifications: notificationsSliceReducer,
    bankMis: bankMisSliceReducer,
    misDsaChecker: misDsaCheckerReducer
});

export default dsa;