import { createSlice } from '@reduxjs/toolkit';

const bankMisStructureSlice = createSlice({
  name: 'bankMisStructure',
  initialState: {
    structures: [],
    banks: [],
    mappingFields: [],
    editStructure: {},
    structureCount: null,
    success: null,
  },
  reducers: {
    fetchBankMisStructureCode(state, action) {
      state.structures = action.payload;
    },
    getBankMisStructureById(state, action) {
      state.editStructure = action.payload;
    },
    setBankCodeCount(state, action) {
      state.structureCount = action.payload ? action.payload : 0;
    },
    setSuccess(state, action) {
      state.success = action.payload;
    },
    fetchMappingFields(state, action) {
      state.mappingFields = action.payload;
    },
    setBanksOfBankMisStructure(state, action) {
      state.banks = action.payload;
    }
  },
});

export const {
  fetchBankMisStructureCode, fetchMappingFields,
  setBankCodeCount, getBankMisStructureById,
  setSuccess, setBanksOfBankMisStructure
} = bankMisStructureSlice.actions;

export default bankMisStructureSlice.reducer;
