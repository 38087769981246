import { createSlice } from '@reduxjs/toolkit';

const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    notificationsList: {},
    alertList: {},
    editNotification: {},
    alertListCount: 0,
    allCount: 0,
    unread: 0
  },
  reducers: {
    fetchNotificationsList(state, action) {
      state.notificationsList = action?.payload;
    },
    fetchNotificationById(state, action) {
      state.editNotification = action?.payload;
    },
    fetchAlertNotificationsList(state, action) {
      state.alertList = action?.payload;
    },
    fetchAlertNotificationsCount(state, action) {
      state.alertListCount = action?.payload;
    },
    fetchCountOfNotificationsActions(state, action) {
      state.allCount = action.payload?.countAll;
      state.unread = action.payload?.unread;
    },

  },
});

export const { fetchNotificationsList, fetchNotificationById, fetchAlertNotificationsCount, fetchAlertNotificationsList, fetchCountOfNotificationsActions } = notificationsSlice.actions;

export default notificationsSlice.reducer;