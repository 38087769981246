import React from 'react';
import { List, ListItem, ListItemText, Box, TextField, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';

interface FaqSidebarProps {
    faqItems: any,
    selectedFaq: number;
    setSelectedFaq: (index: number) => void;
}

const FaqSidebar: React.FC<FaqSidebarProps> = ({ faqItems, selectedFaq, setSelectedFaq }) => {
    const params = useParams();
    const navigate = useNavigate();

    const handleItemClick = (e: React.MouseEvent, itemId: number, itemSlug: string) => {
        e.preventDefault();
        setSelectedFaq(itemId);
        navigate(`/help/${params?.id}/${itemSlug}`);
    };

    return (
        <Box>
            <TextField
                label="Type to search"
                variant="outlined"
                size="small"
                fullWidth
                sx={{ mb: 2 }}
            />
            <Typography variant="h6" sx={{ mb: 2 }}>
                All Articles
            </Typography>
            <List>
                {faqItems?.map((item: any, index: number) => (
                    <ListItem
                        button
                        key={`sidebar-${index}-${item?.id}`}
                        onClick={(e) => handleItemClick(e, item?.id, item?.slug)}
                        selected={selectedFaq === item?.id}
                        sx={{
                            backgroundColor: selectedFaq === item?.id ? '#f5f5f5' : 'transparent',
                            borderLeft: selectedFaq === item?.id ? '4px solid #1976d2' : 'none',
                        }}
                    >
                        <ListItemText primary={item?.title} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );
};

export default FaqSidebar;
