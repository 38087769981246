import get from "lodash/get";

export function categoriesSelector(state: any) {
    return get(state, ["admin", "helpCenter", "category"]) ?? [];
}

export function articlesSelector(state: any) {
    return get(state, ["admin", "helpCenter", "categoryArticles"]) ?? [];
}

export function editArticleSelector(state: any) {
    return get(state, ["admin", "helpCenter", "editArticle"]) || null;
}
export function editCategorySelector(state: any) {
    return get(state, ["admin", "helpCenter", "editCategory"]) || null;
}

export function addUpdateSuccessSelector(state: any) {
    return get(state, ["admin", "helpCenter", "addUpdateSuccess"]) || false;
}

export function pubCategoryArticlesSelector(state: any) {
    return get(state, ["admin", "helpCenter", "pubCategoryArticles"]) ?? [];
}

export function categoryCountSelector(state: any) {
    return get(state, ["admin", "helpCenter", "categoryCount"]) ?? 0;
}

export function articlesCountSelector(state: any) {
    return get(state, ["admin", "helpCenter", "articlesCount"]) ?? 0;
}

export function pubArticlesCountSelector(state: any) {
    return get(state, ["admin", "helpCenter", "pubArticlesCount"]) ?? 0;
}