import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

const Channel = Loadable(lazy(() => import('views/application/dsa/channel')));
const AddChannel = Loadable(lazy(() => import('views/application/dsa/channel/AddChannel')));
const ViewChannelProfile = Loadable(lazy(() => import('views/application/dsa/channel/profile')));

const AddApplication = Loadable(lazy(() => import('views/application/dsa/channel/application/AddApplicationWrapper')));
const Application = Loadable(lazy(() => import('views/application/dsa/channel/application')));

const AddApplications = Loadable(lazy(() => import('views/application/dsa/channel/applications/AddApplicationWrapper')));
const Applications = Loadable(lazy(() => import('views/application/dsa/channel/applications')));

const Beneficiary = Loadable(lazy(() => import('views/application/dsa/beneficiary')));
const AddBeneficiary = Loadable(lazy(() => import('views/application/dsa/beneficiary/AddBeneficiary')));

const ChatBot = Loadable(lazy(() => import('views/application/dsa/channel/onboarding/chatbot')));

const ChannelInquiry = Loadable(lazy(() => import('views/application/dsa/channel/inquiryRequest')));
const ViewInquiryProfile = Loadable(lazy(() => import('views/application/dsa/channel/inquiryRequest/profile')));

const AddSlabCategory = Loadable(lazy(() => import('views/application/dsa/channelCategory/AddCategory')));
const CategoryOfPayout = Loadable(lazy(() => import('views/application/dsa/channelCategory')));
const EditOfPayout = Loadable(lazy(() => import('views/application/dsa/channelCategory/EditCategory')));


export const ChannelRoutes = [
    {
        path: '/channel/add-channel',
        element: <AddChannel />
    },
    {
        path: '/channel',
        element: <Channel />
    },
    {
        path: '/channel/chatbot',
        element: <ChatBot />
    },
    {
        path: '/inquiry',
        element: <ChannelInquiry />
    },
    {
        path: '/channel/view/:id',
        element: <ViewChannelProfile />
    },
    {
        path: '/inquiry/:id',
        element: <ViewInquiryProfile />
    },
    {
        path: '/channel/application/add',
        element: <AddApplication />
    },
    {
        path: '/channel/application/view/:id',
        element: <AddApplication />
    },
    {
        path: '/channel/application/list',
        element: <Application />
    },
    {
        path: '/channel/applications/add-application',
        element: <AddApplications />
    },
    {
        path: '/channel/applications/view/:id',
        element: <AddApplications />
    },
    {
        path: '/channel/applications/list-application',
        element: <Applications />
    },
    {
        path: '/channel/beneficiary',
        element: <Beneficiary />
    },
    {
        path: '/channel/add-beneficiary',
        element: <AddBeneficiary />
    },
    {
        path: '/channel/category-list',
        element: <CategoryOfPayout/>
    },
    {
        path: '/channel/category/:id',
        element: <EditOfPayout/>
    },
    {
        path: '/channel/add-category',
        element: <AddSlabCategory/>
    },
];
