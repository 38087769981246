import { createSlice } from '@reduxjs/toolkit';

const misMakerSlice = createSlice({
  name: 'misMaker',
  initialState: {
    channels: [],
    channelsCounts: 0,
    channelsMis: [],
    channelsMisOverview: [],
    channelsMisOverviewCount: [],
    channelsCategories: [],
    channelsCategoryMisCalculations: [],
    channelsMisCount: 0,
    editChannelsMis: {},
    channelsMisBanks: [],
    channelsMisProductType: [],
    channelsMisProduct: [],
    bankMis: [],
  },
  reducers: {
    fetchMakerChannels(state, action) {
      state.channels = action.payload;
    },
    fetchMakerChannelsCounts(state, action) {
      state.channelsCounts = action.payload;
    },
    fetchMakerChannelsOverview(state, action) {
      state.channelsMisOverview = action.payload;
    },
    fetchMakerChannelsOverviewCounts(state, action) {
      state.channelsMisOverviewCount = action.payload;
    },
    fetchMakerChannelsMisList(state, action) {
      state.channelsMis = action.payload;
    },
    fetchChannelsCategoryMisCalculations(state, action) {
      state.channelsCategoryMisCalculations = action.payload;
    },
    fetchChannelsCategory(state, action) {
      state.channelsCategories = action.payload;
    },
    fetchMisProductAndMetaUpdate(state, action) {
      const { productTypes, products, banks } = action.payload;
      return {
        ...state,
        channelsMisProductType: productTypes ? productTypes : [],
        channelsMisBanks: (banks && banks?.length) ? banks : [],
        channelsMisProduct: products ? products : []
      };
    },
    fetchMakerChannelsMisCounts(state, action) {
      state.channelsMisCount = action.payload;
    },
  },
});

export const { fetchMakerChannels, fetchMakerChannelsOverview, fetchMakerChannelsOverviewCounts, fetchChannelsCategory, fetchChannelsCategoryMisCalculations,
  fetchMisProductAndMetaUpdate, fetchMakerChannelsCounts, fetchMakerChannelsMisCounts,
  fetchMakerChannelsMisList } = misMakerSlice.actions;

export default misMakerSlice.reducer;