import { createSlice } from '@reduxjs/toolkit';

const misCheckerSlice = createSlice({
  name: 'misChecker',
  initialState: {
    channels: [],
    channelsMis: [],
    channelsMisLogs: [],
    channelsMisComments: [],
    editChannelsMis: {},
    channelsMisBanks: [],
    channelsMisProductType: [],
    channelsMisProduct: [],
    channelsMisCount: 0,
    bankMis: [],
    invoiceData: [],
    misSearchingBankMeta: [],
    misSearchingProductMeta: [],
    misSearchingSubProductMeta: [],
    misSearchingProductTypeMeta: [],
    channelPayoutPlan: [],
    addUpdateBankSuccess: false,
    createChannelMisSuccess: false,

    channelsMisReview: [],
    channelsMisReviewCount: 0
  },
  reducers: {
    fetchChannels(state, action) { 
      state.channels = action.payload;
    },
    fetchMisSearchingMeta(state, action) { 
      const type = action?.payload?.type;
      if (type === 'bank') state.misSearchingBankMeta = action?.payload?.data || [];
      if (type === 'product_type') state.misSearchingProductTypeMeta = action?.payload?.data || [];
      if (type === 'product') state.misSearchingProductMeta = action?.payload?.data || [];
      if (type === 'sub_product') state.misSearchingSubProductMeta = action?.payload?.data || [];
    },
    fetchChannelsMis(state, action) { 
      state.channelsMis = action.payload;
    },
    fetchChannelsMisLogs(state, action) { 
      state.channelsMisLogs = action.payload;
    },
    fetchChannelsMisComments(state, action) { 
      state.channelsMisComments = action.payload;
    },
    fetchChannelsMisReview(state, action) { 
      state.channelsMisReview = action.payload;
    },
    fetchChannelsMisById(state, action) { 
      state.editChannelsMis = action.payload;
    },
    fetchChannelsMisCount(state, action) { 
      state.channelsMisCount = action.payload;
    },
    fetchChannelsMisReviewCount(state, action) { 
      state.channelsMisReviewCount = action.payload;
    },
    fetchChannelsMisBanks(state, action) { 
      state.channelsMisBanks = action.payload;
    },
    fetchChannelsMisProductType(state, action) { 
      state.channelsMisProductType = action.payload;
    },
    fetchChannelsMisProduct(state, action) { 
      state.channelsMisProduct = action.payload;
    },
    setCreateChannelMisSuccess(state, action) { 
      state.createChannelMisSuccess = action.payload;
    },
    fetchBankMis(state, action) { 
      state.bankMis = action.payload ? action.payload: [];
    },
    fetchInvoiceData(state, action) { 
      state.invoiceData = action.payload ? action.payload: [];
    },
    fetchChannelPlan(state, action) { 
      state.channelPayoutPlan = action.payload ? action.payload: [];
    },
    addUpdateBankSuccess(state, action) { 
      state.addUpdateBankSuccess = action.payload;
    },
  },
});

export const { fetchChannels, fetchChannelsMisComments, fetchChannelsMisLogs, fetchChannelsMisReviewCount, fetchChannelsMisReview, fetchChannelsMisById, fetchChannelsMisCount, fetchChannelsMisProductType, fetchChannelsMisProduct, fetchMisSearchingMeta, fetchChannelsMisBanks, setCreateChannelMisSuccess, fetchChannelsMis , addUpdateBankSuccess, fetchBankMis, fetchChannelPlan, fetchInvoiceData} = misCheckerSlice.actions;

export default misCheckerSlice.reducer;