import { Typography, InputBase, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import SearchIcon from '@mui/icons-material/Search';

const HeroSection: React.FC<any> = () => {
    return (
        <Box
            sx={{
                //backgroundColor: '#5F6EF8',
                color: '#fff',
                py: 6,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
                minWidth: '100%'
            }}
        >
            {/* Main Title */}
            <Typography variant="h2" color={'#ffffff'} fontWeight="bold" gutterBottom>
                How can we help you?
            </Typography>

            {/* Search Bar */}
            <Box sx={{ backgroundColor: '#fff', borderRadius: '50px', display: 'flex', alignItems: 'center', width: '50%', px: 2 }}>
                <InputBase
                    fullWidth
                    placeholder="Type to search"
                    sx={{
                        flex: 1,
                        px: 2,
                        py: 1,
                        color: 'grey',
                        fontSize: '1rem',
                    }}
                />
                <IconButton sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default HeroSection;