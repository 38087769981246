
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import { NotificationProvider } from 'contexts/NotificationsContext';

import ThemeCustomization from 'themes';

import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
import AppMeta from 'AppMeta';

const App = () => {

    return (
        <ThemeCustomization>
            <AppMeta/>
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <NotificationProvider>
                                <Routes />
                                <Snackbar />
                            </NotificationProvider>
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default App;
