
interface DurationInWordsProps {
  targetDate: Date | string | number;
}

function DurationDateWords({ targetDate }: DurationInWordsProps) {
  function getWords(diff: number): string {
    const seconds = Math.max(Math.floor(diff / 1000) % 60, 0);
    const minutes = Math.max(Math.floor(diff / (1000 * 60)) % 60, 0);
    const hours = Math.max(Math.floor(diff / (1000 * 60 * 60)) % 24, 0);

    if (hours > 0) {
      return `${hours} ${pluralize(hours, 'hour')}`;
    } else if (minutes > 0) {
      return `${minutes} ${pluralize(minutes, 'minute')}`;
    } else {
      return `${seconds} ${pluralize(seconds, 'second')}`;
    }
  }

  function pluralize(count: number, word: string): string {
    return count === 1 ? word : word + 's';
  }

  let targetDateTime: number;

  if (typeof targetDate === 'string' || typeof targetDate === 'number') {
    targetDateTime = new Date(targetDate).getTime();
  } else {
    targetDateTime = (targetDate as Date).getTime();
  }

  const now = new Date();
  const difference = now.getTime() - targetDateTime;

  const duration = getWords(difference);

  return <>{duration}</>;
}

export default DurationDateWords;
