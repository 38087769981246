// third-party
import { FormattedMessage } from 'react-intl';

import { NavItemType } from 'types';

// assets
import {
    IconApps, IconUserCheck, IconBasket, IconMessages, IconLayoutKanban, IconMail, IconCalendar, IconNfc,
    IconBuildingCommunity, IconBuildingBank, IconClipboardPlus,
    IconClipboardList, IconBooks, IconBook, IconGizmo, IconUserPlus, IconUsers, IconClipboardText, IconSettings, IconDeviceAnalytics
} from '@tabler/icons';

// constant
const icons = {
    IconApps, IconClipboardPlus,
    IconUserCheck,
    IconBasket,
    IconMessages,
    IconLayoutKanban,
    IconMail,
    IconCalendar,
    IconNfc,
    IconBuildingCommunity,
    IconBuildingBank,
    IconClipboardList,
    IconBook, IconBooks,
    IconGizmo,
    IconUserPlus,
    IconUsers, IconClipboardText, IconSettings, IconDeviceAnalytics
};

// ==============================|| APPLICATION MENU ITEMS ||============================== //

const dsa: NavItemType = {
    id: 'dsa',
    title: 'DSA',
    icon: icons.IconApps,
    type: 'group',
    isDevMode: true,
    children: [
        {
            id: 'channel-onboarding',
            title: 'Channel Onboarding',
            type: 'item',
            url: '/channel-onboarding',
            icon: icons.IconDeviceAnalytics,
            breadcrumbs: false
        },
        {
            id: 'daily-sales',
            title: 'Daily Sales Tracker',
            type: 'item',
            icon: icons.IconUserCheck,
            url: '/daily-sales'
            //disabled: true,
        },
        {
            id: 'company',
            title: 'Company',
            type: 'collapse',
            icon: icons.IconBuildingCommunity,
            //disabled: true,
            children: [
                {
                    id: 'add',
                    title: 'Add Company',
                    type: 'item',
                    url: '/company/add'
                },
                {
                    id: 'list',
                    title: 'Company List',
                    type: 'item',
                    url: '/company/list'

                },
            ]
        },
        {
            id: 'inquiry',
            title: 'Inquiry Request',
            type: 'item',
            icon: icons.IconGizmo,
            url: '/inquiry'
        },
        {
            id: 'channel',
            title: 'Channel',
            type: 'collapse',
            icon: icons.IconGizmo,
            disabled: true,
            children: [
                {
                    id: 'add-channel',
                    title: 'Channel Add',
                    type: 'item',
                    url: '/channel/add-channel'
                },
                {
                    id: 'channel',
                    title: 'Channel List',
                    type: 'item',
                    url: '/channel'
                },
                {
                    id: 'beneficiary',
                    title: 'Beneficiary',
                    type: 'collapse',
                    disabled: true,
                    //icon: icons.IconBuildingBank,
                    children: [
                        {
                            id: 'add-beneficiary',
                            title: 'Add Beneficiary',
                            type: 'item',
                            url: '/channel/add-beneficiary'
                        },
                        {
                            id: 'beneficiary',
                            title: 'View Beneficiary',
                            type: 'item',
                            url: '/channel/beneficiary'
                        },

                    ]
                },
                {
                    id: 'variables',
                    title: 'Custom variables',
                    type: 'item',
                    url: '/channel/variables'
                },
                {
                    id: 'chatbot',
                    title: 'ChatBot',
                    type: 'item',
                    url: '/channel/chatbot'
                },

                {
                    id: 'channel-category',
                    title: 'Category',
                    type: 'collapse',
                    disabled: true,
                    children: [
                        {
                            id: 'add-category',
                            title: 'Add Category',
                            type: 'item',
                            url: '/channel/add-category'
                        },
                        {
                            id: 'category-list',
                            title: 'Category List',
                            type: 'item',
                            url: '/channel/category-list'
                        },

                    ]
                },
                {
                    id: 'agreement',
                    title: 'Agreement',
                    type: 'collapse',
                    disabled: true,
                    children: [
                        {
                            id: 'add',
                            title: 'Add Agreement',
                            type: 'item',
                            url: '/agreement/add'

                        },
                        {
                            id: 'list',
                            title: 'Agreements List',
                            type: 'item',
                            url: '/agreement/list'

                        }
                    ]
                },

            ]
        },
        {
            id: 'bank',
            title: 'Bank',
            type: 'collapse',
            disabled: true,
            icon: icons.IconBuildingBank,
            children: [
                {
                    id: 'list',
                    title: 'Bank List',
                    type: 'item',
                    url: '/bank/list'

                },
                {
                    id: 'bank-code',
                    title: 'Code Master',
                    type: 'collapse',
                    disabled: true,
                    children: [
                        {
                            id: 'add-code',
                            title: 'Add Bank Code',
                            type: 'item',
                            url: '/bank/add-code'
                        },
                        {
                            id: 'code',
                            title: 'View Banks Codes',
                            type: 'item',
                            url: '/bank/code'
                        },
                    ]
                },
                {
                    id: 'bank-structure-master',
                    title: 'Payout Structure',
                    type: 'collapse',
                    children: [
                        {
                            id: 'add-bank-structure',
                            title: 'Add Structure',
                            type: 'item',
                            url: '/payout-manager/structure/add'
                        },
                        {
                            id: 'view-bank-structure',
                            title: 'View Structures',
                            type: 'item',
                            url: '/payout-manager/structure'
                        },
                    ]
                },
                {
                    id: 'structure',
                    title: 'MIS Structure',
                    type: 'collapse',
                    children: [
                        {
                            id: 'add-mis-structure',
                            title: 'Add MIS Structure',
                            type: 'item',
                            url: '/mis/bank/structure/add'
                        },
                        {
                            id: 'view-structure-mis',
                            title: 'View MIS Structure',
                            type: 'item',
                            url: '/mis/bank/structure'
                        },]
                },
                {
                    id: 'product',
                    title: 'Product',
                    type: 'collapse',
                    disabled: true,
                    children: [
                        {
                            id: 'list',
                            title: 'Product List',
                            type: 'item',
                            url: '/product/list'

                        },

                    ]
                },
                {
                    id: 'policies',
                    title: 'Policies',
                    type: 'collapse',
                    disabled: true,
                    children: [
                        {
                            id: 'add',
                            title: 'Add Policy',
                            type: 'item',
                            url: '/policies/add'

                        },
                        {
                            id: 'list',
                            title: 'List Policies',
                            type: 'item',
                            url: '/policies/list'

                        }
                    ]
                },
                {
                    id: 'contact-list',
                    title: 'Bank Contact List',
                    type: 'item',
                    url: '/bank-contact/list'

                },

            ]
        },
        {
            id: 'applications',
            title: 'Applications',
            type: 'collapse',
            disabled: true,
            icon: icons.IconClipboardPlus,
            children: [
                {
                    id: 'add-application',
                    title: 'Add Application',
                    type: 'item',
                    url: '/channel/applications/add-application'
                },
                {
                    id: 'list-application',
                    title: 'Applications List',
                    type: 'item',
                    url: '/channel/applications/list-application'

                },

            ]
        },


        {
            id: 'mis-uploader',
            title: 'MIS Uploader',
            type: 'collapse',
            disabled: true,
            icon: icons.IconBuildingBank,
            children: [
                {
                    id: 'channel-mis',
                    title: 'Channel MIS',
                    type: 'collapse',
                    children: [
                        {
                            id: 'add-channel-mis',
                            title: 'Add Channel MIS',
                            type: 'item',
                            url: '/mis-uploader/add-channel-mis'
                        },
                        {
                            id: 'channel-mis',
                            title: 'Channel MIS List',
                            type: 'item',
                            url: '/mis-uploader/channel-mis'
                        },
                        {
                            id: 'review-mis',
                            title: 'Review Channel MIS',
                            type: 'item',
                            url: '/mis-uploader/review-mis'
                        },
                        {
                            id: 'payout-list',
                            title: 'Channel Payout List',
                            type: 'item',
                            url: '/payout-list'
                        },
                    ]
                },
                {
                    id: 'bank-mis',
                    title: 'Banks MIS',
                    type: 'collapse',
                    children: [

                        {
                            id: 'add-bank-mis',
                            title: 'Add Bank MIS',
                            type: 'item',
                            url: '/mis-uploader/add-bank-mis'
                        },
                        {
                            id: 'bank-mis',
                            title: 'View Bank MIS',
                            type: 'item',
                            url: '/mis-uploader/bank-mis'
                        },

                    ]
                },

            ]
        },
        {
            id: 'mis-maker',
            title: 'AI MIS Maker',
            type: 'collapse',
            disabled: true,
            icon: icons.IconBuildingBank,
            children: [
                {
                    id: 'maker',
                    title: 'MIS Channels Overview',
                    type: 'item',
                    url: '/mis/maker/'
                },
                {
                    id: 'mis-records',
                    title: 'MIS Maker List',
                    type: 'item',
                    url: '/mis/maker/mis-records'
                },
                {
                    id: 'score-settings',
                    title: 'MIS Score Settings',
                    type: 'item',
                    url: '/mis/maker/score-settings'
                },
            ]
        },
        {
            id: 'mis-checker',
            title: 'AI MIS Checker',
            type: 'collapse',
            disabled: true,
            icon: icons.IconBuildingBank,
            children: [
                {
                    id: 'mis-checker',
                    title: 'Checker MIS List',
                    type: 'item',
                    url: '/mis/checker'
                },
            ]
        },
        {
            id: 'payout-manager',
            title: 'AI Payout Manager',
            type: 'collapse',
            disabled: true,
            icon: icons.IconBuildingBank,
            children: [
                {
                    id: 'list',
                    title: 'Channels Payout',
                    type: 'item',
                    url: '/payout-manager/list'
                },
                {
                    id: 'view',
                    title: 'Payout List',
                    type: 'item',
                    url: '/payout-manager/view'
                },
                {
                    id: 'rtgs',
                    title: 'Payout RTGS List',
                    type: 'item',
                    url: '/payout-manager/rtgs'
                },
                {
                    id: 'rtgs-structure',
                    title: 'Rtgs Structure',
                    type: 'collapse',
                    disabled: true,
                    children: [
                        {
                            id: 'create',
                            title: 'Create',
                            type: 'item',
                            url: '/payout-manager/rtgs/structure/create'
                        },
                        {
                            id: 'list-structure',
                            title: 'Structure List',
                            type: 'item',
                            url: '/payout-manager/rtgs/structure/list'
                        },
                    ]
                },

            ]
        },

        {
            id: 'mis-tracker',
            title: 'MIS Tracker',
            type: 'collapse',
            disabled: true,
            icon: icons.IconBuildingBank,
            children: [
                {
                    id: 'view-mis-tracker',
                    title: 'View MIS Tracker',
                    type: 'item',
                    url: '/mis/tracker'
                },
            ]
        },
        {
            id: 'reports',
            title: 'Reports',
            type: 'collapse',
            disabled: true,
            icon: icons.IconBuildingBank,
            children: [
                {
                    id: 'add-reports',
                    title: 'Add Report',
                    type: 'item',
                    url: '/reports/add'
                },
                {
                    id: 'reports-list',
                    title: 'Reports List',
                    type: 'item',
                    url: '/reports/list'
                },
            ]
        },
        {
            id: 'users',
            title: 'Users',
            type: 'collapse',
            icon: icons.IconUsers,
            disabled: false,
            children: [
                {
                    id: 'add-user',
                    title: 'Add User',
                    type: 'item',
                    url: '/user/add'
                },
                {
                    id: 'list-user',
                    title: 'User List',
                    type: 'item',
                    url: '/user/list'
                },
                {
                    id: 'roles-permissions',
                    title: 'Roles & Permissions',
                    type: 'collapse',
                    children: [
                        {
                            id: 'add-role',
                            title: 'Add Role',
                            type: 'item',
                            url: '/user/role/add'
                        },
                        {
                            id: 'list-role',
                            title: 'Role List',
                            type: 'item',
                            url: '/user/role/list'
                        },
                    ]
                }

            ]
        },
        {
            id: 'setting',
            title: 'Settings',
            type: 'collapse',
            icon: icons.IconSettings,
            disabled: false,
            children: [
                {
                    id: 'company',
                    title: 'Account Settings',
                    type: 'collapse',
                    disabled: true,
                    //icon: icons.IconBuildingBank,
                    children: [
                        {
                            id: 'advance-limit',
                            title: 'Advance Limit',
                            type: 'item',
                            url: '/advance-limit'

                        },
                        {
                            id: 'account-profile',
                            title: 'Account Profile',
                            type: 'item',
                            url: '/account-profile'

                        },
                        {
                            id: 'email-smtp',
                            title: 'Email SMTP',
                            type: 'item',
                            url: '/email-smtp'

                        },
                    ]
                },
                {
                    id: 'api-integrations',
                    title: 'API Integrations',
                    type: 'collapse',
                    disabled: true,
                    //icon: icons.IconBuildingBank,
                    children: [
                        {
                            id: 'bank-api',
                            title: 'Bank APIs',
                            type: 'item',
                            url: '/bank-api'

                        },
                        {
                            id: 'sms-api',
                            title: 'SMS APIs',
                            type: 'item',
                            url: '/sms-api'

                        },
                        {
                            id: 'whatsapp-api',
                            title: 'Whatsapp APIs',
                            type: 'item',
                            url: '/whatsapp-api'

                        },
                        {
                            id: 'voice-call-api',
                            title: 'Voice Call APIs',
                            type: 'item',
                            url: '/voice-call-api'

                        },
                        {
                            id: 'smtp-api',
                            title: 'SMTP APIs',
                            type: 'item',
                            url: '/smtp-api'

                        },

                    ]
                },
                {
                    id: 'notification',
                    title: 'Notifications',
                    type: 'collapse',
                    disabled: true,
                    //icon: icons.IconBuildingBank,
                    children: [
                        {
                            id: 'add',
                            title: 'Add Notification',
                            type: 'item',
                            url: '/notification/add'
                        },
                        {
                            id: 'notification',
                            title: 'View Notifications',
                            type: 'item',
                            url: '/notification'
                        },
                        {
                            id: 'notification-settings',
                            title: 'Settings',
                            type: 'item',
                            url: '/notification/settings'
                        },
                        {
                            id: 'Mail Templates',
                            title: 'Mail Templates',
                            type: 'collapse',
                            disabled: true,
                            //icon: icons.IconBuildingBank,
                            children: [
                                {
                                    id: 'mail-templates',
                                    title: 'Template Create',
                                    type: 'item',
                                    url: '/notification/mail/template/create'
                                },
                            ]
                        }
                    ]
                },

                {
                    id: 'whatsapp-chatbot',
                    title: 'Whatsapp Chatbot',
                    type: 'collapse',
                    disabled: true,
                    //icon: icons.IconBuildingBank,
                    children: [
                        {
                            id: 'whatsapp-chatbot',
                            title: 'Chatbot List',
                            type: 'item',
                            url: '/whatsapp'
                        },
                    ]
                },

            ]
        },
    ]
};

export default dsa;





