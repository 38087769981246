import { createSlice } from '@reduxjs/toolkit';

const accountSlice = createSlice({
  name: 'account',
  initialState: {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    accountType: null, 
    account: null, 
    kycAlertShow: false,
  },
  reducers: {
    accountUserLimitUpdate(state, action) {
      const data = action.payload;
      const {user}: any = state;
      if (user) state.user = {...user, ...data};
    },
    accountLogin(state, action) { 
      const data = action.payload;
      state.isLoggedIn = true;
      state.isInitialized = true;
      state.user = data.user;
      state.accountType = data.accountType;
    },
    accountDetailsUpdate(state, action) { 
      state.account = action.payload;
    },
    accountLogout(state, action) { 
      state.isLoggedIn = false;
      state.isInitialized = true;
      state.user = null;
      state.accountType = null;
      state.kycAlertShow = false;
    },
    kycAlertShowAction(state, action) { 
      state.kycAlertShow = true;
    }
  },
});

export const { accountLogin, accountUserLimitUpdate, accountDetailsUpdate, accountLogout, kycAlertShowAction} = accountSlice.actions;

export default accountSlice.reducer;