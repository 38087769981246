import currency from "currency.js";

export const Rupees = (value: currency.Any) => currency(value, { symbol: '₹' });


export const capitalizeEachWord = (str: string) => {
    if (!str) return '';
    return str
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

export const getFinalPayoutPercentage = (payoutReceived: number, payoutPayable: number) => {
    return (payoutReceived * payoutPayable) / 100;
};

export const getReceivedAmount = (disAmount: number, payoutReceived: number) => {
    return (disAmount * payoutReceived) / 100;
};

export const getFinalPayoutAmount = (disAmount: number, payoutFinalPayable: number) => {
    return (disAmount * payoutFinalPayable) / 100;
};

export const getSavedPayoutAmount = (disAmount: number, payoutReceived: number, payoutFinalPayable: number) => {
    const received = (disAmount * payoutReceived) / 100;
    const payable = (received * payoutFinalPayable) / 100;
    return received - payable;
};

export const getTwoDecimal = (str: number, isPercentage?: boolean) => {
    return  `${Number(str).toFixed(2)}${isPercentage ? '%' : ''}`
};

export function objectToArray(obj: any) {
    return Object.entries(obj).map(([key, value]) => {
        if (typeof value !== 'object') {
            return ({ key, value });
        } else {
            return '';
        }
    });
}

export function customStringSimilarity(string1: string , string2: string) {
    const minLength = Math.min(String(string1).length, String(string2).length);
    let matchingCount = 0;

    for (let i = 0; i < minLength; i++) {
        if (string1[i] === string2[i]) {
            matchingCount++;
        }
    }
    const similarity = matchingCount / minLength;
    return similarity;
}

export function compareStrings(string1: string, string2: string, threshold = 0.8) {
    const similarity = customStringSimilarity(string1, string2);

    return similarity > threshold;
}

export const showStatusOfAction = (status: string) => {
    let statusText = '';
    switch (status) {
        case 'pending':
            statusText = 'warning';
            break;
        case 'pendingApproval':
            statusText = 'Pending Approval';
            break;
        case 'pendingReview':
            statusText = 'Pending Review';
            break;
        case 'reviewed':
            statusText = 'Reviewed';
            break;
        case 'approved':
            statusText = 'Approved';
            break;
        case 'pendingConfirmation':
            statusText = 'Pending Review';
            break;
        case 'finalized':
            statusText = 'Finalized';
            break;
        default: statusText = status;
    }
    return statusText;
}

export const showStatusColorBgOfAction = (status: string) => {
    let statusText = '';
    switch (status) {
        case 'pending':
            statusText = 'warning.dark';
            break;
        case 'pendingApproval':
            statusText = 'warning.dark';
            break;
        case 'pendingReview':
            statusText = 'warning.dark';
            break;
        case 'reviewed':
            statusText = 'success.dark';
            break;
        case 'approved':
            statusText = 'success.dark';
            break;
        case 'pendingConfirmation':
            statusText = 'info.dark';
            break;
        case 'finalized':
            statusText = 'success.dark';
            break;
        default: statusText = 'error.dark';
    }
    return statusText;
}