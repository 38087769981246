import { createSlice } from '@reduxjs/toolkit';

const payoutStructureSlice = createSlice({
  name: 'payoutStructure',
  initialState: {
    structures: [],
    banks: [],
    editStructure: {},
    structureCount: null,
    success: null,
  },
  reducers: {
    fetchPayoutStructure(state, action) {
      state.structures = action.payload;
    },
    setBanksOfPayoutStructure(state, action) {
      state.banks = action.payload;
    },
    getPayoutStructureById(state, action) {
      state.editStructure = action.payload;
    },
    setStructureCount(state, action) {
      state.structureCount = action.payload ? action.payload : 0;
    },
    setSuccess(state, action) {
      state.success = action.payload;
    }
  },
});

export const {
  fetchPayoutStructure,
  setStructureCount, getPayoutStructureById,
  setSuccess, setBanksOfPayoutStructure
} = payoutStructureSlice.actions;

export default payoutStructureSlice.reducer;
