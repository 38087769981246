import { createSlice } from '@reduxjs/toolkit';

const dailyTaskSlice = createSlice({
  name: 'tasks',
  initialState: {
    tasks: [],
    banks: [],
    allBanksOfDsa: [],
    channelsOfDsa: [],
    editTask: {},
    taskCount: null,
    success: null,
  },
  reducers: {
    fetchDailyTasks(state, action) {
      state.tasks = action.payload;
    },
    fetchDailyTaskBanks(state, action) {
      state.banks = action.payload;
    },
    fetchAllBanksOfDsa(state, action) {
      state.allBanksOfDsa = action.payload;
    },
    fetchChannelsOfDsa(state, action) {
      state.channelsOfDsa = action.payload;
    },
    getDailyTaskById(state, action) {
      state.editTask = action.payload;
    },
    setTasksCount(state, action) {
      state.taskCount = action.payload ? action.payload : 0;
    },
    setSuccess(state, action) {
      state.success = action.payload;
    }
  },
});

export const {
  fetchDailyTasks, fetchDailyTaskBanks,
  setTasksCount, getDailyTaskById,
  setSuccess, fetchAllBanksOfDsa, fetchChannelsOfDsa
} = dailyTaskSlice.actions;

export default dailyTaskSlice.reducer;
