import dashboard from './dashboard';
import application from './application';
import dsa from './dsa';
import dsaChannel from './user/dsaChannel';
import dsaUser from './user/dsa';
import dsaChannelUser from './user/dsaChannelUser';
import { NavItemType } from 'types';
import dsaChannelOnboarding from './dsaChannelOnboarding';

const menu = [];
//menu.push(dashboard, application);
const location = window.location.host;
const pathArr = location.replace(/^\/|\/$/g, '').split('.').slice();
if (pathArr.indexOf('admin') > -1 && pathArr[0] === 'admin') {
    menu.push(dashboard, application);
} else if (pathArr.length > 1) {
    menu.push(dashboard, dsaChannelOnboarding, dsa, dsaChannel, dsaUser, dsaChannelUser);
}
const menuItems: { items: NavItemType[] } = {
    items: menu
};
export default menuItems;
