import { createSlice } from '@reduxjs/toolkit';

const profileSlice = createSlice({
  name: 'profile',
  initialState: {
    user: {},
    profileAgreement: {},
    userProfileImage: null,
    addUpdateProfileSuccess: false,
    verifyGstStatus: null,
    verifyPanStatus: null,
    verifyBankStatus: null,
    verifyCINStatus: null,
    verifyAadhaarStatus: null,
    verifyAPICallStatus: false,
    aadhaarVerifyAPICallStatus: false,
    aadhaarVerifyAPIResponseData: {},
    userProfileImageUploadSuccess: false,
    userLoginSessions: []
  },
  reducers: {
    fetchUserProfileAction(state, action) { 
      state.user = action.payload;
    },
    fetchUserLoginSessionAction(state, action) { 
      state.userLoginSessions = action.payload;
    },
    fetchUserProfileAgreementAction(state, action) { 
      state.profileAgreement = action.payload;
    },
    userProfileImageActions(state, action) { 
      state.userProfileImage = action.payload;
    },
    addUpdateProfileSuccessAction(state, action) { 
      state.addUpdateProfileSuccess = action.payload;
    },
    verifyGstStatus(state, action) {
      state.verifyGstStatus = action.payload;
    },

    verifyBankStatus(state, action) {
      state.verifyBankStatus = action.payload;
    },
    userProfileImageUploadAction(state, action) { 
      state.userProfileImageUploadSuccess = action.payload;
    },
    verifyPanStatus(state, action) {
      state.verifyPanStatus = action.payload;
    },
    verifyCINStatus(state, action) {
      state.verifyCINStatus = action.payload;
    },
    verifyAadhaarStatus(state, action) {
      state.verifyAadhaarStatus = action.payload;
    },
    kycVerifyAPICallStatusAction(state, action) {
      state.verifyAPICallStatus = action.payload;
    },
    aadhaarVerifyAPICallStatusAction(state, action) {
      state.aadhaarVerifyAPICallStatus = action.payload;
    },
    aadhaarVerifyAPIResponseDataAction(state, action) {
      state.aadhaarVerifyAPIResponseData = action.payload;
    }
  },
});

export const {kycVerifyAPICallStatusAction, fetchUserLoginSessionAction, aadhaarVerifyAPICallStatusAction, aadhaarVerifyAPIResponseDataAction, fetchUserProfileAgreementAction, fetchUserProfileAction, userProfileImageActions, userProfileImageUploadAction, addUpdateProfileSuccessAction,  verifyBankStatus, verifyCINStatus, 
  verifyGstStatus, verifyPanStatus, verifyAadhaarStatus } = profileSlice.actions;

export default profileSlice.reducer;