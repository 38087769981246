import { createSlice } from '@reduxjs/toolkit';

const beneficiarySlice = createSlice({
  name: 'beneficiary',
  initialState: {
    beneficiaries: [],
    beneficiaryCount: null,
    banks: [],
    success: false,
  },
  reducers: {
    fetchBeneficiary(state, action) {
      state.beneficiaries = action.payload;
    },
    fetchBanksOfBeneficiary(state, action) {
      state.banks = action.payload;
    },
    setBeneficiaryCount(state, action) {
      state.beneficiaryCount = action.payload ? action.payload : 0;
    },
    setSuccess(state, action) {
      state.success = action.payload;
    }
  },
});

export const {
  fetchBeneficiary,
  setBeneficiaryCount, fetchBanksOfBeneficiary,
  setSuccess
} = beneficiarySlice.actions;

export default beneficiarySlice.reducer;
