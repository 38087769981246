import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'store';
import { fetchNotificationsListApi } from 'store/state/data-layer/dsa/notifications';
import { fetchUserSessionUpdateLastActiveTimeApi } from 'store/state/data-layer/dsa/profile';

interface EventSourceOptions {
    maxAttempts?: number;
    retryInterval?: number;
}

function useEventSource(tenant: string, user: any, options: EventSourceOptions = {}) {
    const dispatch = useDispatch();
    const location = useLocation();
    const eventSourceRef: any = useRef<EventSource | null>(null);
    const isConnectionEstablished = useRef(false); // Track if the connection is already established
    const [pathname, setPathname] = useState<string>(location.pathname);
    const { maxAttempts = 3, retryInterval = 10000 } = options;
    let attempts = 0;
    const url = process?.env?.REACT_APP_API_BASE_URL;
    const serviceToken = window.localStorage.getItem('serviceToken');

    useEffect(() => {
        setPathname(location.pathname);
    }, [location.pathname]);

    useEffect(() => {
        function initializeEventSource() {
            if (user && user?.type !== 'admin' && serviceToken) {
                const urlEvent = `${url}/dsa/notifications?tenant=${tenant}&userId=${user?.id}&token=${serviceToken}`;
                if (!isConnectionEstablished?.current) eventSourceRef.current = new EventSource(urlEvent, { withCredentials: true });
                if (eventSourceRef.current) {
                    eventSourceRef.current.onopen = () => {
                        console.log('EventSource connection established.');
                        isConnectionEstablished.current = true;
                        attempts = 0; // Reset attempt count on successful connection
                    };

                    eventSourceRef.current.onmessage = (event: { data: any; }) => {
                        console.log('Received message:', event.data);
                        dispatch(fetchNotificationsListApi());
                        if (location?.pathname == '/profile') {
                            dispatch(fetchUserSessionUpdateLastActiveTimeApi());
                        }
                    };

                    eventSourceRef.current.onerror = (error: any) => {
                        console.error('EventSource error occurred:', error);
                        isConnectionEstablished.current = false;
                        if (attempts < maxAttempts) {
                            attempts++;
                            console.log(`Attempting to reconnect (attempt ${attempts} of ${maxAttempts})...`);
                            setTimeout(initializeEventSource, retryInterval);
                        } else {
                            console.error('Max retry attempts reached. Closing EventSource connection.');
                            eventSourceRef.current?.close();
                        }
                    };
                }
            }
        }

        initializeEventSource();
    }, []);

    useEffect(() => {
        dispatch(fetchUserSessionUpdateLastActiveTimeApi());
    }, []);

    useEffect(() => {
        const handleHeartbeat = () => {
            console.log('Heartbeat received');
            if (pathname === '/profile') {
                dispatch(fetchUserSessionUpdateLastActiveTimeApi());
            }
        };

        if (eventSourceRef.current) {
            eventSourceRef.current.addEventListener('heartbeat', handleHeartbeat);
        }

        return () => {
            if (eventSourceRef.current) {
                eventSourceRef.current.removeEventListener('heartbeat', handleHeartbeat);
            }
        };
    }, [pathname, dispatch]);


    return eventSourceRef.current;
}

export default useEventSource;
