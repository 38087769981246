import { createSlice } from '@reduxjs/toolkit';

const companyMasterSlice = createSlice({
  name: 'companyMasters',
  initialState: {
    companyMasters: [],
    companyProfile: {},
    companyMastersCount: null,
    addUpdateCompanySuccess: false
  },
  reducers: {
    fetchCompanyMasters(state, action) { 
      state.companyMasters = action.payload;
    },
    fetchCompanyProfile(state, action) { 
      state.companyProfile = action.payload;
    },
    companyMastersCount(state, action) { 
      state.companyMastersCount = action.payload ? action.payload: 0;
    },

    addUpdateCompanySuccess(state, action) { 
      state.addUpdateCompanySuccess = action.payload;
    },

  },
});

export const { fetchCompanyMasters, companyMastersCount, addUpdateCompanySuccess, fetchCompanyProfile } = companyMasterSlice.actions;

export default companyMasterSlice.reducer;