// NotificationContext.js
import useEventSource from 'hooks/useEvent';
import { createContext, useContext, useEffect } from 'react';
import { useSelector } from 'store';
import { fetchAccountNotificationsAllCountSelector, fetchAccountNotificationsAllUnseenCountSelector, notificationsListSelector } from 'store/state/selectors/notifications';
import { NotificationsContextType } from 'types/auth';

const NotificationContext = createContext<NotificationsContextType | null>(null);

export const useNotification: any = () => useContext(NotificationContext);

const host = window.location.host;
let parts = host.split('.')
let sub = parts[0];

export const NotificationProvider = ({ children }: { children: any }) => {
    const notifications = useSelector(notificationsListSelector);
    const count = useSelector(fetchAccountNotificationsAllCountSelector);
    const unread = useSelector(fetchAccountNotificationsAllUnseenCountSelector);
    const state: any = useSelector((state) => state.account);
    const eventSource = useEventSource(sub, state?.user, { maxAttempts: 3 });
    
    useEffect(() => {
        if (eventSource) {
            return () => {
                eventSource.close();
            };
        }
    }, [eventSource]);


    return (
        <NotificationContext.Provider value={{ notifications, count, unread }}>
            {children}
        </NotificationContext.Provider>
    );
};
