import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';


const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const DashboardAnalytics = Loadable(lazy(() => import('views/dashboard/Analytics')));

// const Channel = Loadable(lazy(() => import('views/application/dsa/channel')));
// const AddChannel = Loadable(lazy(() => import('views/application/dsa/channel/AddChannel')));
// const EditChannel = Loadable(lazy(() => import('views/application/dsa/channel/EditChannel')));

const AddApplication = Loadable(lazy(() => import('views/application/dsa/channel/application/AddApplication')));

const Profile = Loadable(lazy(() => import('views/application/dsa/profile')));

const User = Loadable(lazy(() => import('views/application/dsa/users')));
const AddUser = Loadable(lazy(() => import('views/application/dsa/users/AddUser')));
// const EditUser = Loadable(lazy(() => import('views/application/dsa/users/EditUser')));
const EditUser = Loadable(lazy(() => import('views/application/dsa/users/profile')));

const RoleAndPermissions = Loadable(lazy(() => import('views/application/dsa/roles')));
const AddRole = Loadable(lazy(() => import('views/application/dsa/roles/AddRole')));
const EditRole = Loadable(lazy(() => import('views/application/dsa/roles/EditRole')));

const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));

const AddChannelMIS = Loadable(lazy(() => import('views/application/dsa/mis/channel/AddMIS')));
const ChannelMIS = Loadable(lazy(() => import('views/application/dsa/mis/channel')));
const ViewChannelMIS = Loadable(lazy(() => import('views/application/dsa/mis/channel/ViewMis')));



// ==============================|| MAIN ROUTING ||============================== //

const DsaChannelRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <DashboardDefault />,           
        },
        {
            path: '/dashboard/analytics',
            element: <DashboardAnalytics />
        },
        {
            path: '/channel/application/add',
            element: <AddApplication/>
        },
        {
            path: '/channel/application/view/:id',
            element: <AddApplication/>
        },
        {
            path: '/channel/application/list',
            element: <AddApplication/>
        },
        {
            path: '/mis/channel/add',
            element: <AddChannelMIS/>
        },
        {
            path: '/mis/channel/view/:id',
            element: <ViewChannelMIS/>
        },
        {
            path: '/mis/channel',
            element: <ChannelMIS/>
        },
        {
            path: '/profile',
            element: <Profile/>
        },
        {
            path: '/user/list',
            element: <User/>
        },
        {
            path: '/user/add',
            element: <AddUser/>
        },
        {
            path: '/user/edit/:id',
            element: <EditUser/>
        },
        {
            path: '/user/role/list',
            element: <RoleAndPermissions />
        },
        {
            path: '/user/role',
            element: <RoleAndPermissions />
        },
        {
            path: '/user/role/add',
            element: <AddRole/>
        }
        ,
        {
            path: '/user/role/edit/:id',
            element: <EditRole/>
        },
        {
            path: '/*',
            element: <MaintenanceError/>
        }
    ],

};

export default DsaChannelRoutes;
