import { createSlice } from '@reduxjs/toolkit';

const companyBankCodeSlice = createSlice({
  name: 'bankCode',
  initialState: {
    bankCodes: [],
    editBankCode: {},
    bankCodeCount: null,
    success: null,
  },
  reducers: {
    fetchCompanyBankCode(state, action) {
      state.bankCodes = action.payload;
    },
    getCompanyBankCodeById(state, action) {
      state.editBankCode = action.payload;
    },
    setBankCodeCount(state, action) {
      state.bankCodeCount = action.payload ? action.payload : 0;
    },
    setSuccess(state, action) {
      state.success = action.payload;
    }
  },
});

export const {
  fetchCompanyBankCode,
  setBankCodeCount, getCompanyBankCodeById,
  setSuccess
} = companyBankCodeSlice.actions;

export default companyBankCodeSlice.reducer;
