import { createSlice } from '@reduxjs/toolkit';

const accountSlice = createSlice({
  name: 'account',
  initialState: {
    dsa: [],
    dsaMastersCount: null,
    addUpdateDSASuccess: false
  },
  reducers: {
    fetchDSAMasters(state, action) { 
      state.dsa = action.payload;
    },
    dsaMastersCount(state, action) { 
      state.dsaMastersCount = action.payload ? action.payload: 0;
    },

    addUpdateDSASuccess(state, action) { 
      state.addUpdateDSASuccess = action.payload;
    },

  },
});

export const {fetchDSAMasters, dsaMastersCount, addUpdateDSASuccess } = accountSlice.actions;

export default accountSlice.reducer;