import { createSlice } from '@reduxjs/toolkit';

const channelMasterSlice = createSlice({
  name: 'channelMasters',
  initialState: {
    channelMasters: {},
    channelRequests: {},
    channelProfile: {},
    channelKeywords: {},
    channelRequestsCount: null,
    channelMastersCount: null,
    channelPayout: null,
    keywordsCount: null,
    addUpdateChannelSuccess: false,
    addUpdateKeywordSuccess: false,
    createUsernameSuccess: false,
    csvImportSuccess: false,
    verifyGstStatus: null,
    verifyPanStatus: null,
    verifyBankStatus: null,
    verifyCINStatus: null,
  },
  reducers: {
    fetchChannelMasters(state, action) { 
      state.channelMasters = action.payload;
    },
    fetchChannelRequests(state, action) { 
      state.channelRequests = action.payload;
    },
    fetchChannelPayout(state, action) { 
      state.channelPayout = action.payload;
    },
    fetchChannelProfile(state, action) { 
      state.channelProfile = action.payload;
    },
    channelMastersCount(state, action) { 
      state.channelMastersCount = action.payload ? action.payload: 0;
    },
    channelRequestsCount(state, action) { 
      state.channelRequestsCount = action.payload ? action.payload: 0;
    },

    addUpdateChannelSuccess(state, action) { 
      state.addUpdateChannelSuccess = action.payload;
    },

    verifyGstStatus(state, action) {
      state.verifyGstStatus = action.payload;
    },

    verifyBankStatus(state, action) {
      state.verifyBankStatus = action.payload;
    },

    verifyPanStatus(state, action) {
      state.verifyPanStatus = action.payload;
    },
    verifyCINStatus(state, action) {
      state.verifyCINStatus = action.payload;
    },
    csvImportSuccess(state, action) { 
      state.csvImportSuccess = action.payload;
    },
    fetchChannelKeywords(state, action) { 
      state.channelKeywords = action.payload;
    },
   
    keywordsCount(state, action) { 
      state.keywordsCount = action.payload ? action.payload: 0;
    },

    addUpdateKeywordSuccess(state, action) { 
      state.addUpdateKeywordSuccess = action.payload;
    },
    createUsernameSuccessStatusActions(state, action) { 
      state.createUsernameSuccess = action.payload;
    },
  },
});

export const { fetchChannelMasters, 
  channelMastersCount, 
  addUpdateChannelSuccess, 
  verifyGstStatus, 
  verifyPanStatus, 
  verifyBankStatus, 
  verifyCINStatus, createUsernameSuccessStatusActions,
  fetchChannelProfile, fetchChannelPayout,fetchChannelRequests, channelRequestsCount,
   csvImportSuccess, fetchChannelKeywords, keywordsCount, addUpdateKeywordSuccess
 } = channelMasterSlice.actions;

export default channelMasterSlice.reducer;