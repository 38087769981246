import { createSlice } from '@reduxjs/toolkit';

const misPayoutSlice = createSlice({
  name: 'misPayout',
  initialState: {
    payouts: [],
    payoutUsers: [],
    payoutCounts: 0,
    payoutsChecker: [],
    rtgsPayouts: [],
    rtgsStructure: [],
    payoutsCheckerCount: 0,
    rtgsStructureCount: 0,
    rtgsPayoutCount: 0,
    payoutsCount: 0,
    editPayout: null,
    editRtgsPayout: null,
    selectedMisForPayout: [],
    addUpdatePayoutSuccess: false,
  },
  reducers: {
    fetchMisPayoutList(state, action) {
      state.payouts = action.payload;
    },
    fetchMisPayoutUsersList(state, action) {
      state.payoutUsers = action.payload;
    },
    fetchMisPayoutUsersCount(state, action) {
      state.payoutCounts = action.payload;
    },
    fetchRtgsStructureList(state, action) {
      state.rtgsStructure = action.payload;
    },
    fetchRgtsMisPayoutList(state, action) {
      state.rtgsPayouts = action.payload;
    },
    fetchMisPayoutsCheckerList(state, action) {
      state.payoutsChecker = action.payload;
    },
    fetchMisPayoutCount(state, action) {
      state.payoutsCount = action.payload;
    },
    fetchRtgsMisPayoutCount(state, action) {
      state.rtgsPayoutCount = action.payload;
    },
    fetchRtgsStructureCount(state, action) {
      state.rtgsStructureCount = action.payload;
    },
    fetchMisPayoutCheckerCount(state, action) {
      state.payoutsCheckerCount = action.payload;
    },
    fetchMisEditPayout(state, action) {
      state.editPayout = action.payload;
    },
    fetchRtgsMisEditPayout(state, action) {
      state.editRtgsPayout = action.payload;
    },
    fetchSelectedMisForPayout(state, action) {
      state.selectedMisForPayout = action.payload;
    },
    addUpdateMisPayoutSuccess(state, action) {
      state.addUpdatePayoutSuccess = action.payload;
    },
  },
});

export const { fetchMisPayoutList, fetchMisPayoutUsersList, fetchMisPayoutUsersCount, fetchRtgsStructureCount, fetchRtgsStructureList, fetchRtgsMisEditPayout, fetchRgtsMisPayoutList, fetchRtgsMisPayoutCount, fetchMisPayoutCheckerCount, fetchMisPayoutsCheckerList, fetchMisPayoutCount, fetchMisEditPayout, fetchSelectedMisForPayout, addUpdateMisPayoutSuccess, } = misPayoutSlice.actions;

export default misPayoutSlice.reducer;