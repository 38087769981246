import { createSlice } from '@reduxjs/toolkit';

const channelAgreementSlice = createSlice({
  name: 'channelAgreements',
  initialState: {
    agreements: [],
    agreementCount: null,
    editAgreement: null,
    permissions: {},
    success: false,
  },
  reducers: {
    fetchAgreements(state, action) {
      state.agreements = action.payload;
    },
    editAgreement(state, action) {
      state.editAgreement = action.payload;
    },
   
    setAgreementCount(state, action) {
      state.agreementCount = action.payload ? action.payload : 0;
    },
    setAgreementSuccess(state, action) {
      state.success = action.payload;
    }
  },
});

export const {
  fetchAgreements,
  setAgreementCount,
  setAgreementSuccess, editAgreement
} = channelAgreementSlice.actions;

export default channelAgreementSlice.reducer;
