import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// login routing
const AuthVerify = Loadable(lazy(() => import('views/application/dsa/pages/auth/AuthVerify')));
const AuthMetaVerify = Loadable(lazy(() => import('views/application/dsa/pages/auth/AuthMetaVerify')));
const AuthLogin = Loadable(lazy(() => import('views/application/dsa/pages/auth/Login1')));
const AuthRegister = Loadable(lazy(() => import('views/application/dsa/pages/auth/Register1')));
const AuthForgotPassword = Loadable(lazy(() => import('views/application/dsa/pages/auth/ForgotPassword1')));

// ==============================|| AUTH ROUTING ||============================== //

const DsaLoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: '/',
            element: <AuthLogin />
        },
        {
            path: '/login',
            element: <AuthLogin />
        },
        {
            path: '/register',
            element: <AuthRegister />
        },
        {
            path: '/forgot',
            element: <AuthForgotPassword />
        },
        {
            path: '/verify',
            element: <AuthVerify />
        },
        {
            path: '/user/verify',
            element: <AuthMetaVerify />
        },
        {
            path: '/*',
            element: <AuthLogin />
        }
    ]
};

export default DsaLoginRoutes;
