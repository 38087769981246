import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';


const ChannelOnboarding = Loadable(lazy(() => import('views/application/dsa/channel/onboarding')));
const Welcome = Loadable(lazy(() => import('views/application/dsa/channel/onboarding/timelineObjects/Welcome')));
const MaintenanceError = Loadable(lazy(() => import('views/pages/maintenance/Error')));
const Profile = Loadable(lazy(() => import('views/application/dsa/profile')));


// ==============================|| MAIN ROUTING ||============================== //

const DsaChannelOnboardingRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: '/dashboard',
            element: <Welcome />,           
        },
        {
            path: '/channel-onboarding',
            element: <ChannelOnboarding />,           
        },
        {
            path: '/profile',
            element: <Profile/>
        },
        {
            path: '/*',
            element: <MaintenanceError/>
        }
    ],

};

export default DsaChannelOnboardingRoutes;
