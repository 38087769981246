import { useEffect, useState } from 'react';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Button, CircularProgress, Dialog, Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';

// assets
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'store';
import useQuery from 'hooks/useQuery';
import axios from 'utils/axios';
import { openSnackbar } from 'store/slices/snackbar';
import SubCard from 'ui-component/cards/SubCard';
import useAuth from 'hooks/useAuth';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
        padding: theme.spacing(2)
    },
    '& .MuDialogActions-root': {
        padding: theme.spacing(1)
    }
}));

export default function AuthMetaVerify(props: { open: boolean, handleClose: () => void; }) {
    const theme = useTheme();
    const auth = useAuth();
    const navigate = useNavigate();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
    const location = useLocation();
    const path = location.pathname;
    const getQuery = useQuery();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<any>('');
    const [isSuccess, setIsSuccess] = useState<any>('');
    const emailAuthKey: any = getQuery.get('email');
    const mobileAuthKey: any = getQuery.get('mobile');
    const handleClose = () => {
        navigate('/', { replace: true });
        props.handleClose();
    };

    const checkAndGetToken = async () => {
        setIsError('');
        setIsSuccess('');

        if (auth && (emailAuthKey || mobileAuthKey)) {
            try {
                const response = await axios.post('/dsa/users/meta-verify', { type: emailAuthKey ? 'email' : 'mobile', authKey: emailAuthKey || mobileAuthKey });
                if (response?.data?.success) {
                    setIsSuccess(response?.data?.message);
                    navigate('/', { replace: true });
                } else {
                    setIsLoading(false);
                    setIsError(response?.data?.message || 'Auth key not valid or expired.')
                }
            } catch (err: any) {
                setIsLoading(false);
                console.log(err, '==============');
                setIsError(err?.message || 'Auth key not valid or expired.')
            }


        }
    }


    useEffect(() => {
        if (isSuccess !== '') {
            dispatch(
                openSnackbar({
                    open: true,
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    message: isSuccess,
                    variant: 'alert',
                    alert: {
                        color: 'success'
                    }
                }));
            setIsLoading(false);
            handleClose();
            setIsSuccess('');
        }

    }, [isSuccess]);

    useEffect(() => {
        if (props?.open && path) {
            setIsLoading(true);
            setTimeout(async () => {
                if (path === '/user/verify') {
                    checkAndGetToken();
                }
            }, 1000);
        }
    }, [path]);


    return (
        <div>
            <BootstrapDialog disableEscapeKeyDown={true} fullWidth onClose={handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
                <SubCard>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12}>
                            <Grid
                                container
                                direction={matchDownSM ? 'column-reverse' : 'row'}
                                alignItems={matchDownSM ? 'center' : 'inherit'}
                                justifyContent={matchDownSM ? 'center' : 'space-between'}
                            >
                                <Grid item>
                                    <Stack
                                        justifyContent={matchDownSM ? 'center' : 'flex-start'}
                                        textAlign={matchDownSM ? 'center' : 'inherit'}
                                    >
                                        <Typography
                                            color={theme.palette.secondary.main}
                                            gutterBottom
                                            variant={matchDownSM ? 'h3' : 'h2'}
                                        >
                                            {`Verify ${emailAuthKey ? 'Email' : 'Mobile number'}`}
                                        </Typography>
                                        <Typography color="textPrimary" gutterBottom variant="h4">

                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider sx={{ width: '100%' }} />


                        {isLoading && <Grid item xs={12} sx={{ textAlign: 'center' }}>
                            <Typography variant="caption">
                                {`Verify ${emailAuthKey ? 'email' : 'mobile number'} in progress...`}
                            </Typography>
                        </Grid>}
                        {isLoading && <Grid item xs={12} sx={{ position: 'relative', padding: '30px' }}>

                            {isLoading && <CircularProgress size={20} sx={{ position: 'absolute', top: '50%', left: '50%' }} />}

                        </Grid>}
                        {!isLoading && <Grid item xs={12}>
                            <Stack direction="row" justifyContent={matchDownSM ? 'center' : 'flex-start'}>
                                <Typography variant="subtitle1" textAlign={matchDownSM ? 'center' : 'inherit'}>
                                    Click on Recheck and again try to verify auth key.
                                </Typography>

                            </Stack>
                        </Grid>}
                        {(!isLoading && !!isError) && <Grid item xs={12}>
                            <Stack direction="row" justifyContent={matchDownSM ? 'center' : 'flex-start'}>
                                {!!isError && <Typography color={'red'} variant="caption" textAlign={matchDownSM ? 'center' : 'inherit'}>
                                    {isError}
                                </Typography>}

                            </Stack>
                        </Grid>}
                        <Grid item xs={12}>
                            <AnimateButton>
                                <Button disableElevation fullWidth size="large" type="button" variant="contained" color="secondary" onClick={(e) => {
                                    //e.stopPropagation();
                                    setIsLoading(true);
                                    setTimeout(async () => {
                                        if (path === '/user/verify') {
                                            await checkAndGetToken();
                                        }

                                    }, 1000);
                                }}>
                                    Recheck
                                </Button>
                            </AnimateButton>
                        </Grid>
                    </Grid>
                </SubCard>
            </BootstrapDialog>
        </div>
    );
}
