import { combineReducers } from 'redux';
import accountReducer from './data-slices/admin/accountSlice';
import usersReducer from './data-slices/admin/userSlice';
import companyMasterSliceReducer from './data-slices/admin/companyMasterSlice';
import bankMasterSliceReducer from './data-slices/admin/bankMasterSlice';
import productMasterSliceReducer from './data-slices/admin/productMasterSlice';
import productTypesSliceReducer from './data-slices/admin/productTypesSlice';
import channelMasterSliceReducer from './data-slices/admin/channelMasterSlice';
import roleReducer from './data-slices/admin/roleSlice';
import helpCenterReducer from './data-slices/admin/helpCenterSlice';

const admin = combineReducers({
    account: accountReducer,
    users: usersReducer,
    companyMasters: companyMasterSliceReducer,
    bankMasters: bankMasterSliceReducer,
    productMasters: productMasterSliceReducer,
    productTypes: productTypesSliceReducer,
    channelMasters: channelMasterSliceReducer,
    roles: roleReducer,
    helpCenter: helpCenterReducer,

})

export default admin;