// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Typography
} from '@mui/material';

import { useNotification } from 'contexts/NotificationsContext';
import DurationDateWords from 'views/components/DurationInDateWords';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import Check from '@mui/icons-material/Check';
// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = () => {
    const theme = useTheme();
    const { notifications } = useNotification();
    
    
    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    //pl: 7
                }
            }}
        >
            {notifications['notifications'] && notifications['notifications']?.map((data: any, i: number) => {
                if (i < 20) {
                    return (<ListItemWrapper key={`notifications-${i}`}>
                        <ListItem alignItems="center">
                            <Typography variant="subtitle1" display="inline-block"  gutterBottom>
                                <ListItemText sx={{fontSize: '14px'}} primary={data?.data?.title ? data?.data?.title : 'Not Available!'} />
                            </Typography>
                        </ListItem>
                        <Grid container direction="row" className="list-container">
                            <Grid item xs={12}>
                                <Typography variant="subtitle2">{data?.data?.message ? data?.data?.message : ''}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="caption" display="flex" justifyContent={'flex-end'} alignItems={'center'} gutterBottom>
                                    {!data?.isRead ? <Check sx={{ padding: '2px', }} fontSize='small'></Check> : <DoneAllIcon sx={{ padding: '2px', }} color='primary' fontSize='small'></DoneAllIcon>}
                                    <DurationDateWords targetDate={data?.dateCreated} />
                                </Typography>
                            </Grid>
                        </Grid>
                        <Divider />
                    </ListItemWrapper>)
                }
            })
            }
        </List>
    );
};

export default NotificationList;
