import { memo, useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import useConfig from 'hooks/useConfig';
import userAuth from 'hooks/useAuth';

import LAYOUT_CONST from 'constant';
import { HORIZONTAL_MAX_ITEM } from 'config';

// types
import { NavItemType } from 'types';

// ==============================|| SIDEBAR MENU LIST ||============================== //
interface PermissionItem {
    name: string;
    checked: boolean;
}

interface PermissionGroup {
    name: string;
    items: PermissionItem[];
    checked: boolean;
}

type UserPermissions = PermissionGroup[];

const hasPermission = (permissions: UserPermissions, requiredPermissions: string[] = [], access: string): boolean => {
    // Ensure requiredPermissions is an array
    if (!Array.isArray(requiredPermissions)) {
        return false;
    }

    for (let perm of permissions) {
        if (requiredPermissions.includes(perm.name)) {
            for (let item of perm.items) {
                if (item.name === access && item.checked) {
                    return true;
                }
            }
        }
    }
    return false;
};


const filterMenu = (menu: any[], permissions: UserPermissions): NavItemType[] => {
    return menu.reduce<NavItemType[]>((filteredMenu, menuItem) => {
        const { permissions: requiredPermissions = [], access = 'read', children } = menuItem;
        // Check if user has permission for this item
        if (hasPermission(permissions, requiredPermissions, access)) {
            const filteredItem = { ...menuItem };

            // Recursively filter children
            if (children && children.length > 0) {
                filteredItem.children = filterMenu(children, permissions);
            }

            filteredMenu.push(filteredItem);
        }

        return filteredMenu;
    }, []);
};


const MenuList = ({ menu }: { menu: any }) => {
    const theme = useTheme();
    const { layout } = useConfig();
    const currentUser: any = userAuth();
    const isDevelopment = currentUser?.user?.isDevelopment === '1' || false; // Use optional chaining here as well
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const [menuItemsState, setMenuItemState] = useState<any>();
    // last menu-item to show in horizontal menu bar
    const lastItem = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd ? HORIZONTAL_MAX_ITEM : null;
    
    useEffect(() => {
        if (menu) {
            if (currentUser?.user?.type === 'channel_user') {
                const data = { ...menu }
                const channelUser = data?.items?.find((d: { id: string; }) => d?.id === 'dsaChannelUser');
                const filteredMenu = filterMenu(channelUser?.children || [], currentUser?.user?.role?.permissions);
                channelUser.children = filteredMenu;
                setMenuItemState(data);
            } else {
                setMenuItemState(menu);
            }
        }
    }, [currentUser?.user, menu]);


    let lastItemIndex = menuItemsState?.items?.length - 1;
    let remItems: NavItemType[] = [];
    let lastItemId: string;

    if (lastItem && lastItem < menuItemsState?.items?.length) {
        lastItemId = menuItemsState?.items[lastItem - 1]?.id!;
        lastItemIndex = lastItem - 1;
        remItems = menuItemsState?.items?.slice(lastItem - 1, menuItemsState?.items?.length).map((item: { title: any; children: any; }) => ({
            title: item.title,
            elements: item.children
        }));
    }

    const navItems = menuItemsState?.items?.filter((d: { id: string; isDevMode: any; }) => d?.id === 'dashboard' || !d?.isDevMode)?.slice(0, lastItemIndex + 1)?.map((item: any) => {
        switch (item?.id) {
            case 'dsaUser':
            case 'dsaChannel':
            case 'dsaChannelUser':
            case 'dashboard':
            case 'dsaChannelOnboarding':
            case 'admin':
                if ((currentUser?.user?.type === 'dsa' || currentUser?.user?.type === 'dsa_user') && item?.id === 'dsaUser' ||
                    (currentUser?.user?.type === 'channel' && item?.id === 'dsaChannel') ||
                    (currentUser?.user?.type === 'channel_user' && item?.id === 'dsaChannelUser') ||
                    item?.id === 'dashboard' ||
                    currentUser?.user?.type === 'request' && item?.id === 'dsaChannelOnboarding' ||
                    (currentUser?.user?.type === 'admin' || currentUser?.user === 'user') && item?.id === 'admin') {
                    switch (item.type) {
                        case 'group':
                            return <NavGroup key={item.id} item={item} lastItem={lastItem!} remItems={remItems} lastItemId={lastItemId} isAdmin={item.id === 'admin'} />;
                        default:
                            return (
                                <Typography key={item.id} variant="h6" color="error" align="center">
                                    Menu Items Error
                                </Typography>
                            );
                    }
                }
                break;
            default:
                break;
        }
    });
    const navItemsIsDevMode = menuItemsState?.items.filter((d: any) => d?.isDevMode).slice(0, lastItemIndex + 1).map((item: any) => {
        switch (item.id) {
            case 'dsa':
            case 'dsaChannel':
            case 'dashboard':
                if ((currentUser?.user?.type === 'dsa' || currentUser?.user?.type === 'dsa_user') && item?.id === 'dsa' ||
                    (currentUser?.user?.type === 'channel' || currentUser?.user?.type === 'channel_user') && item?.id === 'dsaChannel' ||
                    item?.id === 'dashboard') {
                    switch (item?.type) {
                        case 'group':
                            return <NavGroup key={item.id} item={item} lastItem={lastItem!} remItems={remItems} lastItemId={lastItemId} isAdmin={false} />;
                        default:
                            return (
                                <Typography key={item.id} variant="h6" color="error" align="center">
                                    Menu Items Error
                                </Typography>
                            );
                    }
                }
                break;
            default:
                break;
        }
    });



    return <>{isDevelopment ? navItemsIsDevMode : navItems}</>;
};

export default memo(MenuList);
