import { createSlice } from '@reduxjs/toolkit';

const channelVariableSlice = createSlice({
  name: 'channelVariables',
  initialState: {
    variables: [],
    fields: [],
    variableCount: null,
    permissions: {},
    success: false,
  },
  reducers: {
    fetchVariables(state, action) {
      state.variables = action.payload;
    },
    fetchVariableFields(state, action) {
      state.fields = action.payload;
    },
    setVariableCount(state, action) {
      state.variableCount = action.payload ? action.payload : 0;
    },
    setVariablePermissions(state, action) {
      state.permissions = action.payload;
    },
    setSuccess(state, action) {
      state.success = action.payload;
    }
  },
});

export const {
  fetchVariables,
  setVariableCount,
  setVariablePermissions, fetchVariableFields,
  setSuccess
} = channelVariableSlice.actions;

export default channelVariableSlice.reducer;
