import { lazy } from 'react';
import Loadable from 'ui-component/Loadable';

const MisTracker = Loadable(lazy(() => import('views/application/dsa/mis/misTracker')));
const MisChannelMaker = Loadable(lazy(() => import('views/application/dsa/mis/misMaker/channel')));
const MisChannelChecker = Loadable(lazy(() => import('views/application/dsa/mis/misChecker')));
const MisBankChecker = Loadable(lazy(() => import('views/application/dsa/mis/misMaker/bank')));
const MisCheckerView = Loadable(lazy(() => import('views/application/dsa/mis/misMaker/ViewMisTracker')));
const MisCheckerViewList = Loadable(lazy(() => import('views/application/dsa/mis/misMaker')));
const CreatePayout = Loadable(lazy(() => import('views/application/dsa/mis/channel/CreatePayout')));
const ChannelTransaction = Loadable(lazy(() => import('views/application/dsa/mis/channel/ChannelTransactionsPayout')));

const AddChannelMIS = Loadable(lazy(() => import('views/application/dsa/mis/channel/Introduction')));
const ChannelMIS = Loadable(lazy(() => import('views/application/dsa/mis/channel')));
const ReviewMisList = Loadable(lazy(() => import('views/application/dsa/mis/channel/ReviewMisList')));
const ViewChannelMIS = Loadable(lazy(() => import('views/application/dsa/mis/channel/ViewMis')));
const EditChannelMIS = Loadable(lazy(() => import('views/application/dsa/mis/channel/EditMis')));
const PolicyParameters = Loadable(lazy(() => import('views/application/dsa/mis/misMaker/PolicyParameters')));

const AddBankMIS = Loadable(lazy(() => import('views/application/dsa/mis/bank/Introduction')));
const ViewBankMIS = Loadable(lazy(() => import('views/application/dsa/mis/bank')));
const EditBankMis = Loadable(lazy(() => import('views/application/dsa/mis/bank/EditMis')));
const RecordOfMis = Loadable(lazy(() => import('views/application/dsa/mis/bank/RecordOfMis')));
const BankMisBunchIndex = Loadable(lazy(() => import('views/application/dsa/mis/bank/BunchIndex')));
const AddMisStructure = Loadable(lazy(() => import('views/application/dsa/bankMisStructure/AddMisStructure')));
const EditMisStructure = Loadable(lazy(() => import('views/application/dsa/bankMisStructure/MappingSettings')));
const ViewMisStructure = Loadable(lazy(() => import('views/application/dsa/bankMisStructure')));


const MisPayoutList = Loadable(lazy(() => import('views/application/dsa/mis/payoutChecker/view')));
const MisPayoutView = Loadable(lazy(() => import('views/application/dsa/mis/payoutChecker/ChannelTransactionsPayout')));
const MisPayoutViewById = Loadable(lazy(() => import('views/application/dsa/mis/payoutChecker/ViewPayout')));
const MisPayoutInvoice = Loadable(lazy(() => import('views/application/dsa/mis/payoutChecker/CreateInvoice')));

const AddStructureMaster = Loadable(lazy(() => import('views/application/dsa/payoutStructure/AddStructureMaster')));
const StructureMaster = Loadable(lazy(() => import('views/application/dsa/payoutStructure')));
const EditStructureMaster = Loadable(lazy(() => import('views/application/dsa/payoutStructure/EditStructureMaster')));
const PayoutRtgs = Loadable(lazy(() => import('views/application/dsa/mis/payoutChecker/RtgsPayoutList')));
const ViewRtgsPayout = Loadable(lazy(() => import('views/application/dsa/mis/payoutChecker/ViewRtgsPayout')));
const CreateBankRtgs = Loadable(lazy(() => import('views/application/dsa/mis/payoutChecker/bankRtgsStructure/CreateBankRtgs')));
const BankStructureRtgsList = Loadable(lazy(() => import('views/application/dsa/mis/payoutChecker/bankRtgsStructure/index')));


export const MisRoutes = [
    {
        path: '/mis-uploader/add-channel-mis',
        element: <AddChannelMIS />
    },
    {
        path: '/mis-uploader/view/:id',
        element: <ViewChannelMIS />
    },
    {
        path: '/mis-uploader/edit/:id',
        element: <EditChannelMIS />
    },
    {
        path: '/mis-uploader/channel-mis',
        element: <ChannelMIS />
    },
    {
        path: '/payout-list',
        element: <ChannelTransaction />
    },
    {
        path: '/mis-uploader/review-mis',
        element: <ReviewMisList />
    },
    {
        path: '/payout-mis/edit/:id',
        element: <CreatePayout />
    },
    {
        path: '/mis/tracker',
        element: <MisTracker />
    },

    {
        path: '/mis/checker/banks',
        element: <MisBankChecker />
    },
    {
        path: '/mis/maker',
        element: <MisChannelMaker />
    },
    {
        path: '/mis/maker/mis-records',
        element: <MisCheckerViewList />
    },
    {
        path: '/mis/maker/score-settings',
        element: <PolicyParameters />
    },
    {
        path: '/mis/checker',
        element: <MisChannelChecker />
    },
    {
        path: '/mis-uploader/bank-mis',
        element: <ViewBankMIS />
    },
    {
        path: '/mis-uploader/bank-mis/view/:id',
        element: <EditBankMis />
    },
    {
        path: '/mis-uploader/bank-mis/bunches',
        element: <BankMisBunchIndex />
    },
    {
        path: '/mis-uploader/records-bank-mis',
        element: <RecordOfMis />
    },
    {
        path: '/mis-uploader/records-bank-mis',
        element: <RecordOfMis />
    },
    {
        path: '/mis-uploader/add-bank-mis',
        element: <AddBankMIS />
    },
    {
        path: '/mis/bank/structure/add',
        element: <AddMisStructure />
    },
    {
        path: '/mis/bank/structure/view/:id',
        element: <EditMisStructure />
    },
    {
        path: '/mis/bank/structure/',
        element: <ViewMisStructure />
    },
    {
        path: '/payout-manager/structure/add',
        element: <AddStructureMaster />
    },
    {
        path: '/payout-manager/list',
        element: <MisPayoutList />
    },
    {
        path: '/payout-manager/view/:id',
        element: <MisPayoutViewById />
    },
    {
        path: '/payout-manager/view',
        element: <MisPayoutView />
    },
    {
        path: '/payout-manager/invoice',
        element: <MisPayoutInvoice />
    },
    {
        path: '/payout-manager/structure',
        element: <StructureMaster />
    },
    {
        path: '/payout-manager/structure/:id',
        element: <EditStructureMaster />
    },
    {
        path: '/payout-manager/rtgs',
        element: <PayoutRtgs />
    },
    {
        path: '/payout-manager/rtgs/view/:id',
        element: <ViewRtgsPayout />
    },
    {
        path: '/payout-manager/rtgs/structure/create',
        element: <CreateBankRtgs />
    },
    {
        path: '/payout-manager/rtgs/structure/list',
        element: <BankStructureRtgsList />
    },
];
