import { Chip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { memo } from 'react';
import { capitalize } from 'utils/utils';

const DynamicChip = ({ statusColor, status, statusProgress, variant, color, isItemNev, isRight}: { statusColor: string, color?: boolean, status: string, statusProgress: any, variant?: boolean, isItemNev?: boolean, isRight?: boolean }) => {
  const theme = useTheme();

  const chipSX = {
    fontSize: '12px',
    height: variant ? '17px' : 'inherit',
    color: color ? '#ffffff' : theme.palette.grey[900]
  };

  const chipErrorSX = {
    ...chipSX,
    borderColor: theme.palette.error.main,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.error.main,
    marginRight: '5px'
  };

  const chipWarningSX = {
    ...chipSX,
    borderColor: theme.palette.warning.dark,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.main
  };

  const chipSuccessSX = {
    ...chipSX,
    borderColor: theme.palette.success.dark,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.main,
  };

  let chipStyles;
  switch (statusColor) {
    case 'red':
      chipStyles = chipErrorSX;
      break;
    case 'green':
      chipStyles = chipSuccessSX;
      break;
    case 'yellow':
      chipStyles = chipWarningSX;
      break;
    default:
      chipStyles = { color: '#ffffff' };
      break;
  }

  const chipLabel = typeof statusProgress === 'string' ? capitalize(status === 'draft' ? 'draft' : !statusProgress ? 'submitted' : status === 'rejected' ? 'rejected' : statusProgress) : capitalize(status || statusProgress || '');

  return (
    <Chip
      sx={isItemNev ? {position: 'absolute', right: '0px', ...chipStyles } : chipStyles}
      //variant={variant ? "filled" : "outlined"}
      size="small"
      label={chipLabel}
    />
  );
};

export default memo(DynamicChip);
