
import { Container, Grid, styled, Typography, useTheme } from '@mui/material';
import HeroSection from './HeroSection';
import AppBar from 'ui-component/extended/AppBar';
import BgLightHero from 'assets/images/landing/bg-header-hero.jpg';


const HeaderWrapper = styled('div')(({ theme }) => ({
    overflowX: 'hidden',
    overflowY: 'clip',
    backgroundSize: 'cover',
    backgroundImage: `url(${BgLightHero})`,
    backgroundRepeat: 'no-repeat',
    //animation: '15s wings ease-in-out infinite',
    [theme.breakpoints.down('md')]: {}
}));
// ==============================|| LANDING - HEADER PAGE ||============================== //

const HeaderSection = ({ id }: { id: string }) => {
    const theme = useTheme();

    return (
        <HeaderWrapper id={id ?? 'home'} theme={theme}>
            <AppBar />
            <Container sx={{ height: id === 'home' ? '70vh' : '60vh', minHeight: id === 'home' ? '550px' : '450px', sm: { minHeight: '300px' }, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ mt: { xs: 10, sm: 6, md: 18.75 }, mb: { xs: 2.5, md: 10 } }}
                >
                    <Grid item xs={12} md={12}>

                        <Typography variant='h1' marginTop={'20px'} textAlign={'center'} fontSize={'3.5rem'} color={'#ffffff'}>Help Center</Typography>

                        <HeroSection />

                    </Grid>
                </Grid>
            </Container>
        </HeaderWrapper>
    );
};

export default HeaderSection;
