import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, CssBaseline, Container, Breadcrumbs, Link, Typography } from '@mui/material';
import FaqSidebar from './FaqSidebar';
import FaqContent from './FaqContent';
import HeaderSection from './HeaderSection';
import { styled, useTheme } from '@mui/material/styles';
import FooterSection from 'views/pages/landing/FooterSection';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom'; // Import Link from react-router-dom
import { useDispatch, useSelector } from 'store';
import { pubCategoryArticlesSelector } from 'store/state/adminSelectors/helpSelectors';
import { getPublicArticles } from 'store/state/data-layer/admin/helpCenter';

const SectionWrapper = styled('div')({
    paddingTop: 100,
    paddingBottom: 100
});

const App: React.FC = () => {
    const theme = useTheme();
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedFaq, setSelectedFaq] = useState<number>(0);
    const [category, setCategory] = useState<any>({});
    const [content, setContent] = useState<any>({});
    const [scrollPosition, setScrollPosition] = useState<number>(0); // State for storing scroll position
    const cardData = useSelector(pubCategoryArticlesSelector);

    useEffect(() => {
        if (params?.id) {
            dispatch(getPublicArticles({ limit: 0, page: 0, search: '', category: params?.id }));
        }
    }, [dispatch, params?.id]);

    useEffect(() => {
        if (params?.id && cardData?.length > 0) {
            const categoryObj = cardData.find((d: any) => d.slug === params.id);
            if (categoryObj) {
                setCategory(categoryObj);
            }
        }
    }, [cardData, params?.id]);

    useEffect(() => {
        if (category) {
            const articlesObj = category?.articles?.find((d: any, i: number) => d.slug === params?.articleId || i == 0);
            if (articlesObj) {
                setContent(articlesObj);
                setSelectedFaq(articlesObj?.id);
            }
        }
    }, [category, params?.articleId]);

    // Save scroll position when navigating
    useEffect(() => {
        const handleBeforeUnload = () => {
            setScrollPosition(window.scrollY); // Save current scroll position
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        return () => window.removeEventListener('beforeunload', handleBeforeUnload);
    }, []);

    // Restore scroll position after navigating
    useEffect(() => {
        if (scrollPosition) {
            window.scrollTo(0, scrollPosition); // Restore saved scroll position
        }
    }, [scrollPosition, content]); // Restore position when content changes

    return (
        <Box>
            <HeaderSection id='category' />
            <Container maxWidth="lg" sx={{ py: 4 }}>
                <CssBaseline />
                <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 4 }}>
                    <Link component={RouterLink} underline="hover" color="inherit" to="/">
                        Home
                    </Link>
                    <Link component={RouterLink} underline="hover" color="inherit" to="/help">
                        Help Center
                    </Link>
                    <Typography color="text.primary">{category?.name}</Typography>
                </Breadcrumbs>

                <Box sx={{ backgroundColor: '#f8f9fa', p: 2, borderRadius: 2 }}>
                    <Grid container spacing={3}>
                        {/* Left Sidebar */}
                        <Grid item xs={12} md={3}>
                            <FaqSidebar faqItems={category?.articles} selectedFaq={selectedFaq} setSelectedFaq={setSelectedFaq} />
                        </Grid>

                        {/* Right Content */}
                        <Grid item xs={12} md={9}>
                            <Paper elevation={3} sx={{ p: 4 }}>
                                <FaqContent content={content} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <SectionWrapper sx={{ bgcolor: theme.palette.mode === 'dark' ? 'background.default' : 'dark.900', pb: 0 }}>
                <FooterSection />
            </SectionWrapper>
        </Box>
    );
};

export default App;
